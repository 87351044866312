<template>
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-2">
          <h4 class="card-title">Config AE List</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options
                :access="'master_config_ae'"
                :hideAdd="false"
                :hideDetail="true"
                :isRefreshEnabled="true"
                :isRefreshed="isRefreshed"
                @onAddItemClicked="openAddForm"
                @onRefreshed="getData('Refreshed')"/>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col md="12">
            <config-ae-list
              :access="'master_config_ae'"
              :data="data"
              :loading="isLoading"
              :pagination="pagination"
              @onActionDelete="openDelete"
              @onActionEdit="openEdit"
              @onActionView="openDetail"
              @onPageChanged="onPageChanged"
              @update="onUpdated"/>
          </b-col>
        </b-row>
      </div>
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayForm">
        <config-ae-form
          :data="currentConfigAe"
          :errors="errors"
          :isSubmit="isSubmit"
          @onSubmitClicked="onSubmitClicked"
          @onCancelAddClicked="onCancel" />
      </b-modal>
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayDeletion">
        <delete-item
          :isSubmit="isSubmit"
          @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion"/>
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import ButtonOptions from '@/components/directives/button-options';
import DeleteItem from '@/components/directives/delete-item';
import ConfigAeForm from '@/components/config/ae/Form';
import ConfigAeList from '@/components/config/ae/List';
import ConfigAeSvc from '@/services/ConfigAeSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'Role',
  components: {
    DeleteItem,
    ConfigAeForm,
    ConfigAeList,
    ButtonOptions,
  },
  data() {
    return {
      currentConfigAe: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 50,
        totalSize: 0,
      },
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const resp = await ConfigAeSvc.deleteConfigAE(data.mcaeId);
        if (resp.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = await false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(resp.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const resp = await ConfigAeSvc.getConfigAE({
          ...requestBuilder.buildFilters(this.pagination, this.filter),
        });
        this.data = resp.data;
        this.pagination.totalSize = resp.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        console.error('getData error', e);
        this.$toast.error('Something Wrong When Fetching Data');
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.currentConfigAe = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Delete data complete', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    async onSubmitClicked(form) {
      this.errors = {};
      this.isSubmit = true;
      try {
        let resp = {};
        if (form.mcaeId) {
          const mcaeId = form.mcaeId;

          delete form?.mcaeId;
          delete form?.mcaeUpdatedUserId;
          delete form?.mrtaDevc;
          delete form?.mcaeCreatedUserId;
          delete form?.mcaeCreatedTime;

          resp = await ConfigAeSvc.updateConfigAE(mcaeId, form);
        } else {
          resp = await ConfigAeSvc.createConfigAE(form);
        }
        if (resp.status.error === 0) {
          await this.getData('Success');
          this.currentConfigAe = {};
          this.displayForm = false;
        } else {
          const errors = resp.status.message;
          if (typeof errors === 'object') {
            this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(resp.data.status.message);
          }
        }
        this.isSubmit = false;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.currentConfigAe = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(data) {
      this.$router.push({ name: 'RoleDetail', params: { id: data.mcaeId } });
    },
    openEdit(data) {
      this.displayForm = true;
      this.currentConfigAe = { ...this._.find(this.data, (v) => v.mcaeId === data.mcaeId) };
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>

</style>
