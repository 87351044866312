<template>
  <div class="d-sm-block d-md-flex d-lg-flex align-items-center pb-2">
    <!-- date filter -->
    <div class="mx-2">
      <span class="d-md-block text-muted small text-left font-weight-bold">Select Range</span>
      <v-date-picker v-model="dateRange" is-range mode="datetime" :masks="masks" is24hr>
        <template #default="{ inputValue, inputEvents, isDragging }">
          <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
            <input class="form-control bg-light border rounded date-input"
              :class="isDragging ? 'text-muted' : 'text-dark'" :value="inputValue.start + ' - ' + inputValue.end"
              v-on="inputEvents.start" />
          </div>
        </template>
      </v-date-picker>
    </div>

    <div class="mx-2">
      <span class="d-md-block text-muted small text-left font-weight-bold">Interval</span>
      <select name="" class="form-control" v-model="selectedInterval">
        <option v-if="minute" value="minute">Minute</option>
        <option value="hour">Hour</option>
        <option value="day">Day</option>
        <option value="week">Week</option>
      </select>
    </div>

    <div class="mx-2">
      <span class="d-md-block text-muted small text-left font-weight-bold">Range Type </span>
      <select name="" class="form-control" v-model="selectedRangeType">
        <option value="lastMonth">Last Month</option>
        <option value="thisMonth">This Month</option>
        <option value="lastWeek">Last Week</option>
        <option value="thisWeek">This Week</option>
        <option value="yesterday">Yesterday</option>
        <option value="today">Today</option>
        <option value="custom">Custom</option>
      </select>
    </div>

    <div class="mx-2">
      <span class="d-md-block text-muted small text-left font-weight-bold">Asset</span>
      <select name="" class="form-control" v-model="selectedAsset">
        <option v-for="asset in assets" :key="asset.massId" :value="asset.massId">{{ asset.massName }}</option>
      </select>
    </div>

    <div class="mx-2">
      <span class="d-sm-none d-md-block text-muted small text-left font-weight-bold">&nbsp;</span>
      <button class="btn btn-primary btn-apply" @click="onApplyFilter">Apply</button>
    </div>

    <div v-if="!hideReport" class="mx-2">
      <span class="d-sm-none d-md-block text-muted small text-left font-weight-bold">&nbsp;</span>
      <ReportDownloadDropdown />
    </div>

    <div v-if="downloadExcel" class="mx-2">
      <span class="d-sm-none d-md-block text-muted small text-left font-weight-bold">&nbsp;</span>
      <button class="btn btn-success" @click="onDownloadExcel">
        Download XLSX
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import ReportDownloadDropdown from '@/components/report/ReportDownloadDropdown.vue';

export default {
  name: 'DataFilter',
  components: {
    ReportDownloadDropdown,
  },
  props: {
    onApplyFilter: {
      type: Function,
      required: true,
    },
    hideReport: {
      type: Boolean,
      required: false,
    },
    downloadExcel: {
      type: Boolean,
      required: false,
    },
    onDownloadExcel: {
      type: Function,
      required: false,
    },
    minute: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      masks: {
        input: 'DD-MM-YYYY HH:mm',
      },
    };
  },
  computed: {
    currAsset() {
      const currMassId = this.$store.getters['filter/currentMassId'];
      const assets = this.$store.getters['asset/assets'];
      if (!assets) return null;

      return assets.find((asset) => asset.massId === currMassId);
    },
    dateRange: {
      get() {
        const { start, end } = this.$store.getters['filter/date'];
        const startDate = new Date(moment(start).valueOf());
        const endDate = new Date(moment(end).valueOf());

        return { start: startDate, end: endDate };
      },
      set(val) {
        const start = moment(val.start);
        const end = moment(val.end);

        this.$store.commit('filter/SET_DATE', { start, end });
      },
    },
    ...mapState('asset', ['assets']),
    ...mapState('filter', {
      currentMassId: (state) => state.currentMassId,
      interval: (state) => state.interval,
      rangeType: (state) => state.rangeType,
      isLoading: (state) => state.isLoading,
    }),
    selectedInterval: {
      get() {
        return this.interval;
      },
      set(val) {
        this.$store.dispatch('filter/setInterval', val);
      },
    },
    selectedAsset: {
      get() {
        return this.currentMassId;
      },
      set(val) {
        this.$store.dispatch('filter/setCurrentMassId', val);
      },
    },
    selectedRangeType: {
      get() {
        return this.rangeType;
      },
      set(val) {
        this.$store.dispatch('filter/setRangeType', val);
      },
    },
  },
};
</script>

<style scoped>
.date-input{
  width: 320px;
}

@media (max-width: 991px) {
  .date-input{
    width: 100%;
  }
  .btn-apply{
    margin-top: 10px;
  }
}

</style>
