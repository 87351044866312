<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="!data.userCreatedTime">Add Config Rpm</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row mb-3">
        <div role="group" class="form-group col-md-6">
          <label for="mcrpDevcId" class="d-block form-label text-left">
            Device ID:
          </label>
          <b-form-select
            class="form-control"
            v-model="data.mcrpDevcId"
            :options="devices"
            :class="errors.mcrpDevcId ? 'is-invalid' : ''"
          >
            <template v-slot:first>
              <option :value="null" disabled>Select Device</option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback v-if="errors.mcaeDevcId">
            {{ Object.values(errors.mcaeDevcId) }}
          </b-form-invalid-feedback>
        </div>

        <div role="group" class="form-group col-md-6">
          <label for="mcrpType" class="d-block form-label text-left">
            Type:
          </label>
          <select
            class="form-control"
            id="mcrpType"
            v-model="data.mcrpType"
            :class="errors.mcrpType ? 'is-invalid' : ''"
          >
            <option value="" disabled>Select Type</option>
            <option value="STARBOARD">STARBOARD</option>
            <option value="PORT">PORT</option>
          </select>
          <b-form-invalid-feedback v-if="errors.mcrpType">
            {{ Object.values(errors.mcrpType) }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row mb-3">
        <div role="group" class="form-group col-md-6">
          <label for="mcrpNo" class="d-block form-label text-left">
            Number:
          </label>
          <input
            class="form-control"
            type="text"
            id="mcrpNo"
            placeholder="Number"
            v-model="data.mcrpNo"
            :class="errors.mcrpNo ? 'is-invalid' : ''"
          >
          <b-form-invalid-feedback v-if="errors.mcrpNo">
            {{ Object.values(errors.mcrpNo) }}
          </b-form-invalid-feedback>
        </div>

        <div role="group" class="form-group col-md-6">
          <label for="mcrpMinValue" class="d-block form-label text-left">
            Minimum Value:
          </label>
          <input
            class="form-control"
            type="number"
            id="mcrpMinValue"
            placeholder="Minimum Value"
            v-model="data.mcrpMinValue"
            :class="errors.mcrpMinValue ? 'is-invalid' : ''"
          >
          <b-form-invalid-feedback v-if="errors.mcrpMinValue">
            {{ Object.values(errors.mcrpMinValue) }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row mb-3">
        <div role="group" class="form-group col-md-6">
          <label for="mcrpConsumptRateByTime" class="d-block form-label text-left">
            Consumption Rate by Time:
          </label>
          <input
            class="form-control"
            type="number"
            step="0.1"
            id="mcrpConsumptRateByTime"
            placeholder="Consumption Rate by Time"
            v-model="data.mcrpConsumptRateByTime"
            :class="errors.mcrpConsumptRateByTime ? 'is-invalid' : ''"
          >
          <b-form-invalid-feedback v-if="errors.mcrpConsumptRateByTime">
            {{ Object.values(errors.mcrpConsumptRateByTime) }}
          </b-form-invalid-feedback>
        </div>

        <div role="group" class="form-group col-md-6">
          <label for="mcrpConsumptRateByPower" class="d-block form-label text-left">
            Consumption Rate by Power:
          </label>
          <input
            class="form-control"
            type="number"
            step="0.1"
            id="mcrpConsumptRateByPower"
            placeholder="Consumption Rate by Power"
            v-model="data.mcrpConsumptRateByPower"
            :class="errors.mcrpConsumptRateByPower ? 'is-invalid' : ''"
          >
          <b-form-invalid-feedback v-if="errors.mcrpConsumptRateByPower">
            {{ Object.values(errors.mcrpConsumptRateByPower) }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row mb-3">
        <div role="group" class="form-group col-md-6">
          <label for="mcrpConsumptRateByRpm" class="d-block form-label text-left">
            Consumption Rate by RPM:
          </label>
          <input
            class="form-control"
            type="number"
            step="0.1"
            id="mcrpConsumptRateByRpm"
            placeholder="Consumption Rate by RPM"
            v-model="data.mcrpConsumptRateByRpm"
            :class="errors.mcrpConsumptRateByRpm ? 'is-invalid' : ''"
          >
          <b-form-invalid-feedback v-if="errors.mcrpConsumptRateByRpm">
            {{ Object.values(errors.mcrpConsumptRateByRpm) }}
          </b-form-invalid-feedback>
        </div>

        <div role="group" class="form-group col-md-6">
          <label for="mcrpIsSingleEngine" class="d-block form-label text-left">
            Is Single Engine:
          </label>
          <select
            class="form-control"
            id="mcrpIsSingleEngine"
            v-model="data.mcrpIsSingleEngine"
            :class="errors.mcrpIsSingleEngine ? 'is-invalid' : ''"
          >
            <option :value="0">No</option>
            <option :value="1">Yes</option>
          </select>
          <b-form-invalid-feedback v-if="errors.mcrpIsSingleEngine">
            {{ Object.values(errors.mcrpIsSingleEngine) }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="form-group">
        <b-button
          @click="onCancelAddClicked"
          class="mr-1"
          size="sm"
          :disabled="isSubmit"
          variant="outline-danger"
        >
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button
          type="submit"
          @click="onSubmitClicked"
          :disabled="isSubmit"
          variant="primary"
          size="sm"
          class="ml-1"
        >
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceSvc from '../../../services/DeviceSvc';

export default {
  name: 'MasterConfigRpmForm',
  props: ['data', 'errors', 'isSubmit'],
  data() {
    return {
      devices: [],
    };
  },
  mounted() {
    this.fetchDeviceData();
  },
  methods: {
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const data = { ...this.data };
      this.$emit('onSubmitClicked', data);
    },
    async fetchDeviceData() {
      try {
        const response = await DeviceSvc.getDevices({ params: { max: 1000 } });
        console.debug('response', response);

        const devices = response.data.data;

        this.devices = devices.filter((device) => device.devcType === 'rpm').map((device) => ({
          text: device.devcUniqueId,
          value: device.devcId,
        }));

        console.debug('devices : ', devices.filter((device) => device.devcType === 'rpm'));
      } catch (e) {
        console.error(e);
        this.$toast.error('error when fetching device data');
      }
    },
  },
};
</script>

<style scoped>
</style>
