<template>
  <b-container fluid>
    <div class="iq-card mt-3 px-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-3">
          <h4 class="card-title">Sensor Type</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options :access="'master_sensor_type'" :hideAdd="false" :hideDetail="true" :isRefreshEnabled="true"
                :isRefreshed="isRefreshed" @onAddItemClicked="openAddForm" @onRefreshed="getData('Refreshed')" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col md="12">
            <div class="table-responsive">
              <b-table striped hover fixed head-variant="dark" show-empty responsive :busy="isLoading" :fields="fields"
                :items="canRead('master_sensor_type') ? data : []"
                style="border-radius: 10px; overflow: scroll; max-height: 500px;">
                <template v-slot:table-busy="">
                  <div class="text-center text-danger my-2">
                    <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
                  </div>
                </template>
                <template v-slot:cell(matyCreatedTime)="data">
                  {{ dateWithDDMMYYYYFormat(data.value) }}
                </template>
                <template v-slot:cell(action)="data">
                  <div class="text-center">
                    <button-options :access="'master_sensor_type'" :hideAdd="true" :hideDelete="false" :hideDetail="true"
                      :hideEdit="false" @onDeleteClicked="openDelete(data.item)" @onEditClicked="openEdit(data.item)" />
                  </div>
                </template>
              </b-table>

              <b-pagination v-model="pagination.current" :per-page="pagination.pageSize"
                :total-rows="pagination.totalSize" @change="onPageChanged"></b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayForm">
        <div class="iq-card mb-0" style="min-width: 300px;">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title" v-if="!current.mstyId">Add New Sensor Type</h4>
              <h4 class="card-title" v-if="current.mstyId">Edit Sensor Type</h4>
            </div>
          </div>
          <div class="iq-card-body">
            <div class="row mb-3">
              <div role="group" class="form-group col-md-6">
                <label for="userId" class="d-block form-label text-left">
                  Sensor Type Name:
                </label>
                <div class="bv-no-focus-ring">
                  <div role="group" class="input-group">
                    <input class="form-control" type="text" id="mstyName" placeholder="Sensor Type Name"
                      v-model="current.mstyName" :class="errors.mstyName ? 'is-invalid' : ''">
                    <b-form-invalid-feedback v-if="errors.userId">
                      {{ Object.values(errors.userId) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <b-button @click="onCancel" class="mr-1" size="sm" :disabled="isSubmit"
                variant="outline-danger">
                <div class="mx-2">Cancel</div>
              </b-button>
              <b-button type="submit" @click="onSubmitClicked" :disabled="isSubmit" variant="primary" size="sm"
                class="ml-1">
                <div class="mx-2">
                  <i v-if="isSubmit" class="ri-loader-line"></i>
                  Submit
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayDeletion">
        <delete-item :isSubmit="isSubmit" @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import DeleteItem from '@/components/directives/delete-item';
import ButtonOptions from '@/components/directives/button-options';
import SensorTypeSvc from '@/services/SensorTypeSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'MasterSensorType',
  components: {
    DeleteItem, ButtonOptions,
  },
  data() {
    return {
      sensorType: new SensorTypeSvc(),
      current: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
      fields: [
        {
          key: 'mstyName',
          label: 'Fuel Type Name',
          class: 'text-left',
        },
        {
          key: 'mstyCreatedUserId',
          label: 'Created By',
          class: 'text-center',
        },
        {
          key: 'mstyCreatedTime',
          label: 'Created',
          class: 'text-center',
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
        },
      ],
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const deleteResponse = await this.sensorType.delete(data.mstyId);
        if (deleteResponse.status.error === 0) {
          this.$toast.success(notification);
          this.displayDeletion = false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(deleteResponse.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      try {
        this.isLoading = true;
        this.isRefreshed = true;
        const Response = await this.sensorType.getAll({
          ...requestBuilder.buildFilters(this.pagination, this.filter),
        });

        const Data = Response.data;

        this.data = Data;

        this.pagination.totalSize = Response.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        console.error('get data error : ', e);
        this.$toast.error('Something wrong when fetching data');
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.current = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Sensor Type Deleted', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    async onSubmitClicked() {
      try {
        this.errors = {};
        this.isSubmit = true;

        const Data = {
          mstyName: this.current.mstyName,
        };

        if (!this.current.mstyId) { // jika tidak ada mstyId maka create
          const createResponse = await this.sensorType.create(Data);

          if (createResponse.status.error === 0) {
            await this.getData('Success');
            this.current = {};
          } else {
            this.$toast.error('Error when saving data');
          }
        } else { // jika ada mstyId maka update
          const updateResponse = await this.sensorType.update(this.current.mstyId, Data);

          if (updateResponse.status.error === 0) {
            await this.getData('Success');
            this.current = {};
          } else {
            this.$toast.error('Error when Update data');
          }
        }
      } catch (e) {
        console.error('onSubmitClicked error : ', e);
        this.$toast.error('Error when submiting data');
      } finally {
        this.displayForm = false;
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.current = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openEdit(data) {
      this.displayForm = true;
      this.current = { ...this._.find(this.data, (v) => v.mstyId === data.mstyId) };
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
