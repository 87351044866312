import axios from 'axios';

export default {
  async getReports(start, end, massId, reportType = []) {
    const params = {
      devcMassId: massId,
      aggregatedUnit: 'HOUR',
      aggregatedLength: 1,
      reports: reportType,
    };

    const interval = parseInt(end) - parseInt(start);
    if (interval <= 86400) params.dataTables = true;

    const response = await axios.get(`/reports/report/print/period/${start}/${end}`, {
      params,
      responseType: 'blob',
    });

    return {
      blob: response.data,
      filename: response.headers['content-disposition'].split('filename="')[1].split('"')[0],
    };
  },
};
