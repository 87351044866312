/* eslint-disable no-restricted-syntax */
// import { LatLng, icon } from 'leaflet';
import moment from 'moment';
import axios from 'axios';

import shipOnline from '@/assets/images/map/online-ship.svg';
import shipOffline from '@/assets/images/map/offline-ship.svg';
import markerOnline from '@/assets/images/map/online-marker.svg';
import markerOffline from '@/assets/images/map/offline-marker.svg';

import { generateTimestampsInSeconds } from '@/util/date';

import AssetSvc from '@/services/AssetSvc';
import PulseSvc from '@/services/PulseSvc';
import SummarySvc from '@/services/SummarySvc';
import DeviceSvc from '@/services/DeviceSvc';

import TugBoatIcon from '@/assets/images/map/tug-boat-icon.svg';
import headingOnline from '@/assets/images/map/online-heading.svg';
import headingOffline from '@/assets/images/map/offline-heading.svg';

const state = {
  assets: [],
};

// eslint-disable-next-line no-unused-vars
const getLocation = async ({ lat, long }) => {
  try {
    const reverseGeoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDowItyYfzjbq6S-7EouF2bj_bZDTGIbH8&language=id`;
    // eslint-disable-next-line no-await-in-loop
    const response = await axios.get(reverseGeoCodeUrl);
    const addressComponent = response.data.results[0] ? response.data.results[0].address_components : [];
    let formattedAddress = '-';

    if (addressComponent[1] && addressComponent[2]) {
      formattedAddress = `${addressComponent[1]?.short_name}, ${addressComponent[2]?.short_name}`;
    } else {
      // formattedAddress = addressComponent[0]?.short_name;
      // eslint-disable-next-line no-await-in-loop
      const fetchLocation = await fetch(`/geonames/findNearbyJSON?lat=${lat}&lng=${long}&username=rgibranz_dev`);
      // eslint-disable-next-line no-await-in-loop
      const location = await fetchLocation.json();
      formattedAddress = location?.geonames[0].toponymName ?? '-';
    }
    return formattedAddress;
  } catch (error) {
    console.error('error', error);
    return '-';
  }
};

const processSummarySeriesData = (summary, firstQueryTimestamp, lastQueryTimestamp) => {
  const timestamps = generateTimestampsInSeconds(firstQueryTimestamp, lastQueryTimestamp, 3600);

  const roundedData = (data) => {
    const rounded = {};
    Object.keys(data || {}).forEach((key) => {
      const roundedKey = Math.round(key / 3600) * 3600;
      rounded[roundedKey] = data[key];
    });
    return rounded;
  };

  const roundedGps = roundedData(summary.gps?.data);
  const roundedRpm = roundedData(summary.rpm?.data);
  const roundedFm = roundedData(summary.flowmeter?.data);
  const roundedAe = roundedData(summary.ae?.data);

  return timestamps.map((timestamp) => {
    const gpsData = roundedGps[timestamp] || {};
    const rpmData = roundedRpm[timestamp] || {};
    const fmData = roundedFm[timestamp] || {};
    const aeData = roundedAe[timestamp] || {};

    const latLong = [gpsData?.additional?.LAST_dlgpLatitude_4, gpsData?.additional?.LAST_dlgpLongitude_5];
    const totalCruise = gpsData.distance;
    const avgSpeed = gpsData.speed;
    const currentAe = Object.values(aeData).find((v) => v.rpm > 0)?.no + 1;
    const portRpm = rpmData.PORT?.rpm;
    const starboardRpm = rpmData.STARBOARD?.rpm;
    const portInFlowrate = fmData.PORT_Flow;
    const starboardInFlowrate = fmData.STARBOARD_Flow;

    return {
      gpsData,
      rpmData,
      fmData,
      aeData,

      dateTime: moment.unix(timestamp).format('DD-MM-YYYY HH:mm:ss'),
      timestamp,
      latLong,
      heading: gpsData.track,
      totalCruise,
      avgSpeed,
      currentAe,
      portRpm,
      starboardRpm,
      portInFlowrate,
      starboardInFlowrate,
    };
  });
};

const mutations = {
  UPDATE_ASSET_STATUS(state, { id, status }) {
    const asset = state.assets.find((asset) => asset.id === id);
    if (asset) {
      asset.status = status;
    }
  },
  UPDATE_ASSET_TRAIL(state, { massId, trail }) {
    const asset = state.assets.find((asset) => asset.massId === massId);
    if (asset) {
      asset.trail = trail;
    }
  },
  SET_ASSETS(state, assets) {
    state.assets = assets;
  },
};

const actions = {
  async getAssetsData({ commit }) {
    const res = await AssetSvc.getAssets();
    commit('SET_ASSETS', res.data.data);
    return res.data.data;
  },
  async getLastData({ commit }) {
    const currTime = Math.floor(Date.now() / 1000);
    const currAssets = Object.values(state.assets);

    const devicePromises = currAssets.map((asset) => {
      const param = {
        findField: 'devcMassId',
        findValue: asset.massId,
      };
      return DeviceSvc.getDevicesByAssetId(param);
    });

    const massDevices = await Promise.all(devicePromises);

    const devices = {
      gps: [],
      ae: [],
      rpm: [],
      flowmeter: [],
    };

    const massDevicesMap = new Map();

    massDevices.forEach((massDevice, index) => {
      const data = massDevice.data.data;
      const asset = currAssets[index];

      massDevicesMap.set(asset.massId, data);

      const gpsDevice = data.find((v) => v.devcType === 'gps');
      const aeDevice = data.find((v) => v.devcType === 'ae');
      const rpmDevice = data.find((v) => v.devcType === 'rpm');
      const fmDevices = data.filter((v) => v.devcType === 'flowmeter');

      if (gpsDevice) devices.gps.push(gpsDevice.devcUniqueId);
      if (aeDevice) devices.ae.push(aeDevice.devcUniqueId);
      if (rpmDevice) devices.rpm.push(rpmDevice.devcUniqueId);
      fmDevices.forEach((fm) => devices.flowmeter.push(fm.devcUniqueId));
    });

    const [gpsPulseDatas, aePulseDatas, rpmPulseDatas, fmPulseDatas] = await Promise.all([
      PulseSvc.getPulse(devices.gps, 'gps'),
      PulseSvc.getPulse(devices.ae, 'ae'),
      PulseSvc.getPulse(devices.rpm, 'rpm'),
      PulseSvc.getPulse(devices.flowmeter, 'flowmeter'),
    ]);

    // Map assets with their device data
    const mapped = currAssets.map((asset) => {
      const newAsset = { ...asset };
      const devices = massDevicesMap.get(asset.massId);

      // GPS Device handling
      const gpsDevice = devices.find((v) => v.devcType === 'gps');
      if (gpsDevice) {
        const gpsData = gpsPulseDatas.find((d) => d.deviceId === gpsDevice.devcUniqueId);
        if (gpsData) {
          const diffTime = parseInt(currTime) - parseInt(gpsData.timestamp);
          newAsset.trail = false;
          newAsset.dotTrail = true;
          newAsset.lastGpsData = gpsData;
          // eslint-disable-next-line no-undef
          newAsset.lastLatLng = L.latLng(gpsData.latitude, gpsData.longitude);
          newAsset.dataStatus = diffTime < 600;
          let assetIcon;

          switch (asset?.massMaty?.matyName.toLowerCase()) {
            case 'tug boat':
              assetIcon = TugBoatIcon;
              break;
            default:
              assetIcon = diffTime > 600 ? shipOffline : shipOnline;
          }

          // eslint-disable-next-line no-undef
          newAsset.headingIcon = L.icon({
            iconUrl: diffTime > 600 ? headingOffline : headingOnline,
            iconSize: [120, 146],
            className: 'heading-icon',
          });

          // eslint-disable-next-line no-undef
          newAsset.icon = L.icon({
            iconUrl: assetIcon,
            iconSize: [60, 86],
            popupAnchor: [0, -41],
            tooltipAnchor: [-50, 50],
            className: 'ship-icon',
          });
          // eslint-disable-next-line no-undef
          newAsset.markerIcon = L.icon({
            iconUrl: diffTime > 600 ? markerOffline : markerOnline,
            iconSize: [35, 61],
            className: 'marker-icon',
          });
        }
      }

      // AE Device handling
      const aeDevice = devices.find((v) => v.devcType === 'ae');
      if (aeDevice) {
        const aeData = aePulseDatas.filter((d) => d.deviceId === aeDevice.devcUniqueId);
        if (aeData) {
          newAsset.lastAeData = aeData;
        }
      }

      // RPM Device handling
      const rpmDevice = devices.find((v) => v.devcType === 'rpm');
      if (rpmDevice) {
        const rpmData = rpmPulseDatas.filter((d) => d.deviceId === rpmDevice.devcUniqueId);
        if (rpmData) {
          newAsset.lastRpmData = rpmData;
        }
      }

      // Flowmeter Device handling
      const fmDevice = devices.filter((v) => v.devcType === 'flowmeter').map((v) => v.devcUniqueId);
      if (fmDevice.length > 0) {
        const fmData = fmPulseDatas.filter((d) => fmDevice.includes(d.deviceId));
        if (fmData) {
          newAsset.lastFmData = fmData;
        }
      }

      return newAsset;
    });

    commit('SET_ASSETS', mapped);
    return mapped;
  },
  async fetchSummary({ commit }, {
    start, end, interval, massId,
  }) {
    const assets = Object.values(state.assets);
    const mappedAssets = [];
    const firstQueryTimestamp = moment(start).unix();
    const lastQueryTimestamp = moment(end).unix();

    const summaryResponse = await SummarySvc.getSummary({
      start: firstQueryTimestamp,
      end: lastQueryTimestamp,
      aggregatedUnit: interval.toUpperCase(),
      devcType: ['gps', 'rpm', 'flowmeter', 'ae'],
      devcMassId: massId,
      showDetails: true,
    });

    for (const asset of assets) {
      if (asset.massId === massId) {
        const mappedCoordinatesData = processSummarySeriesData(summaryResponse.data.data, firstQueryTimestamp, lastQueryTimestamp);

        asset.coordinates = mappedCoordinatesData.map((coordinate) => coordinate.latLong);
        const lastData = mappedCoordinatesData.pop();

        asset.coordinatesData = mappedCoordinatesData;
        asset.summary = summaryResponse.data.data;

        if (lastData.gpsData?.additional?.LAST_dlgpLatitude_4 && lastData.gpsData?.additional?.LAST_dlgpLongitude_5) {
          asset.lastGpsData = lastData.gpsData;
          // eslint-disable-next-line no-undef
          asset.lastLatLng = L.latLng(lastData.gpsData?.additional?.LAST_dlgpLatitude_4, lastData.gpsData?.additional?.LAST_dlgpLongitude_5);
        }
      }
      mappedAssets.push(asset);
    }
    commit('SET_ASSETS', mappedAssets);
    return mappedAssets;
  },
  async fetchAllLastSummary({ commit }) {
    const assets = Object.values(state.assets);
    const mappedAssets = [];

    for (const asset of assets) {
      const lastTimestamp = asset.lastGpsData.timestamp;
      const firstQueryTimestamp = moment((Math.floor(lastTimestamp / 3600) * 3600) * 1000).subtract(1, 'day').unix();
      const lastQueryTimestamp = moment(lastTimestamp * 1000).unix();

      // eslint-disable-next-line no-await-in-loop
      const summaryResponse = await SummarySvc.getSummary({
        start: firstQueryTimestamp,
        end: lastQueryTimestamp,
        aggregatedUnit: 'HOUR',
        devcType: ['gps', 'rpm', 'flowmeter', 'ae'],
        devcMassId: asset.massId,
        showDetails: true,
      });

      const mappedCoordinatesData = processSummarySeriesData(summaryResponse.data.data, firstQueryTimestamp, lastQueryTimestamp);

      asset.coordinates = mappedCoordinatesData.map((coordinate) => coordinate.latLong);
      mappedCoordinatesData.pop();
      asset.coordinatesData = mappedCoordinatesData;
      asset.summary = summaryResponse.data.data;
      mappedAssets.push(asset);
    }

    commit('SET_ASSETS', mappedAssets);
    return mappedAssets;
  },
  async getAllLocationData({ commit }) {
    try {
      const assets = Object.values(state.assets);
      const mappedAssets = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const asset of assets) {
        const lat = asset.lastGpsData.latitude;
        const long = asset.lastGpsData.longitude;
        const reverseGeoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDowItyYfzjbq6S-7EouF2bj_bZDTGIbH8&language=id`;
        // eslint-disable-next-line no-await-in-loop
        const response = await axios.get(reverseGeoCodeUrl);
        const addressComponent = response.data.results[0] ? response.data.results[0].address_components : [];
        let formattedAddress = '-';

        if (addressComponent[1] && addressComponent[2]) {
          formattedAddress = `${addressComponent[1]?.short_name}, ${addressComponent[2]?.short_name}`;
        } else {
          // formattedAddress = addressComponent[0]?.short_name;
          // eslint-disable-next-line no-await-in-loop
          const fetchLocation = await fetch(`/geonames/findNearbyJSON?lat=${lat}&lng=${long}&username=rgibranz_dev`);
          // eslint-disable-next-line no-await-in-loop
          const location = await fetchLocation.json();
          formattedAddress = location?.geonames[0].toponymName;
        }

        asset.location = formattedAddress;
        mappedAssets.push(asset);
      }

      commit('SET_ASSETS', mappedAssets);
      return mappedAssets;
    } catch (error) {
      console.error('Error fetching reverse geocode:', error);
    }
  },
  updateAssetStatus({ commit }, payload) {
    commit('UPDATE_ASSET_STATUS', payload);
  },
  // eslint-disable-next-line no-unused-vars
  updateAssetTrail({ commit }, { massId, trail }) {
    const assets = Object.values(state.assets);

    assets.map((asset) => {
      if (asset.massId === massId) {
        asset.trail = trail;
      }
      return asset;
    });

    commit('SET_ASSETS', assets);
  },
  updateAssetDotTrail({ commit }, { massId, dotTrail }) {
    const assets = Object.values(state.assets);

    assets.map((asset) => {
      if (asset.massId === massId) {
        asset.dotTrail = dotTrail;
      }
      return asset;
    });

    commit('SET_ASSETS', assets);
  },
  resetAssetsData({ commit }) {
    commit('SET_ASSETS', []);
    return [];
  },
};

const getters = {
  assets: (state) => state.assets,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
