<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="!data.userCreatedTime">Create New Bunker Log</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row mb-3">

        <!-- Start Date & Time -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgStartDatetime" class="d-block form-label text-left">
            Bunker Start Date & Time:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <v-date-picker v-model="formData.bklgStartDatetime" mode="datetime" :masks="{ input: 'DD-MM-YYYY HH:mm' }"
                is24hr>
                <template #default="{ inputValue, inputEvents, isDragging }">
                  <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                    <div class="position-relative flex-grow-1">
                      <input class="form-control bg-light border rounded" style="width: 320px;"
                        :class="isDragging ? 'text-muted' : 'text-dark'" :value="inputValue" v-on="inputEvents" />
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <b-form-invalid-feedback v-if="formErrors?.bklgStartDatetime?.same" force-show>
                {{ Object.values(formErrors?.bklgStartDatetime?.same) }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="formErrors?.bklgStartDatetime?.before" force-show>
                {{ Object.values(formErrors?.bklgStartDatetime?.before) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Start Date & Time -->

        <!-- End Date & Time -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgEndDatetime" class="d-block form-label text-left">
            Bunker End Date & Time:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <v-date-picker v-model="formData.bklgEndDatetime" mode="datetime" :masks="{ input: 'DD-MM-YYYY HH:mm' }"
                is24hr>
                <template #default="{ inputValue, inputEvents, isDragging }">
                  <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                    <div class="position-relative flex-grow-1">
                      <input class="form-control bg-light border rounded" style="width: 320px;"
                        :class="isDragging ? 'text-muted' : 'text-dark'" :value="inputValue" v-on="inputEvents" />
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <b-form-invalid-feedback v-if="formErrors?.bklgEndDatetime?.same" force-show>
                {{ Object.values(formErrors?.bklgEndDatetime?.same) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./End Date & Time -->

        <!-- Start Sounding -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgStartSounding" class="d-block form-label text-left">
            Start Sounding In KL
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input class="form-control" type="number" step="0.01" id="bklgStartSounding"
                placeholder="Bunker End Sounding In KL" v-model="formData.bklgStartSounding"
                :class="formErrors?.bklgStartSounding ? 'is-invalid' : ''">
              <b-form-invalid-feedback v-if="formErrors?.bklgStartSounding?.required" force-show>
                {{ formErrors?.bklgStartSounding?.required }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Start Sounding -->

        <!-- End Sounding -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgEndSounding" class="d-block form-label text-left">
            End Sounding In KL
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input class="form-control" type="number" step="0.01" id="bklgEndSounding"
                placeholder="Bunker End Sounding In KL" v-model="formData.bklgEndSounding"
                :class="formErrors?.bklgEndSounding ? 'is-invalid' : ''">
              <b-form-invalid-feedback v-if="formErrors?.bklgEndSounding?.required" force-show>
                {{ formErrors?.bklgEndSounding?.required }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./End Sounding -->

        <!-- Target Bunker -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgTargetBunker" class="d-block form-label text-left">
            Target Bunker In KL
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input class="form-control" type="number" step="0.01" id="bklgTargetBunker"
                placeholder="Bunker Target Bunker In KL" v-model="formData.bklgTargetBunker"
                :class="formErrors?.bklgTargetBunker ? 'is-invalid' : ''">
              <b-form-invalid-feedback v-if="formErrors?.bklgTargetBunker?.required" force-show>
                {{ formErrors?.bklgTargetBunker?.required }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Target Bunker -->

        <!-- Assets -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgMassId" class="d-block form-label text-left">
            Asset :
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-select class="form-control" id="bklgMassId" placeholder="Select Asset"
                v-model="formData.bklgMassId" :options="assets" />
              <b-form-invalid-feedback v-if="formErrors?.bklgMassId?.required" force-show>
                {{ formErrors?.bklgMassId?.required }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Assets -->

        <!-- Fuel Type -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgMftyId" class="d-block form-label text-left">
            Fuel Type :
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-select v-if="!isAddingFuelType" class="form-control" id="bklgMftyId"
                placeholder="Select Fuel Type" v-model="formData.bklgMftyId" :options="fuelTypes" />
              <b-button v-if="!isAddingFuelType" @click="isAddingFuelType = true" variant="outline-primary" size="sm"
                class="ml-2">
                Add Fuel Type
              </b-button>
              <b-form-input v-else v-model="newFuelTypeName" placeholder="Enter new fuel type" class="ml-2" />
              <b-button v-if="isAddingFuelType" @click="addFuelType" variant="primary" size="sm" class="ml-2">
                Save
              </b-button>
              <b-button v-if="isAddingFuelType" @click="isAddingFuelType = false" variant="outline-danger" size="sm"
                class="ml-2">
                Cancel
              </b-button>
              <b-form-invalid-feedback v-if="formErrors?.bklgMftyId?.required" force-show>
                {{ formErrors?.bklgMftyId?.required }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Fuel Type -->

        <!-- Bunker Location -->
        <div role="group" class="form-group col-md-6">
          <label for="bklgMbloId" class="d-block form-label text-left">
            Bunker Location :
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-select v-if="!isAddingBunkerLocation" class="form-control" id="bklgMbloId"
                placeholder="Select Bunker Location" v-model="formData.bklgMbloId" :options="bunkerLocations" />
              <b-button v-if="!isAddingBunkerLocation" @click="isAddingBunkerLocation = true" variant="outline-primary"
                size="sm" class="ml-2">
                Add Bunker Location
              </b-button>
              <b-form-input v-else v-model="newBunkerLocationName" placeholder="Enter new bunker location"
                class="ml-2" />
              <b-button v-if="isAddingBunkerLocation" @click="addBunkerLocation" variant="primary" size="sm"
                class="ml-2">
                Save
              </b-button>
              <b-button v-if="isAddingBunkerLocation" @click="isAddingBunkerLocation = false" variant="outline-danger"
                size="sm" class="ml-2">
                Cancel
              </b-button>

              <b-form-invalid-feedback v-if="formErrors?.bklgMbloId?.required" force-show>
                {{ formErrors?.bklgMbloId?.required }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <!-- ./Bunker Location -->
      </div>

      <div class="form-group">
        <b-button @click="onCancelAddClicked" class="mr-1" size="sm" :disabled="isSubmit" variant="outline-danger">
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button type="submit" @click="onSubmitClicked" :disabled="isSubmit" variant="primary" size="sm" class="ml-1">
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import AssetSvc from '@/services/AssetSvc';
import FuelTypeSvc from '@/services/FuelTypeSvc';
import BunkerLocation from '@/services/BunkerLocationSvc';

export default {
  name: 'BunkerBunkeringLogForm',
  props: ['data', 'isSubmit'],
  data() {
    return {
      assets: [],
      fuelTypes: [],
      bunkerLocations: [],
      formData: {},
      newFuelTypeName: '',
      newBunkerLocationName: '',
      isAddingFuelType: false,
      isAddingBunkerLocation: false,
      formErrors: {}, // Local errors property
    };
  },
  async mounted() {
    await this.fetchData();
    this.initializeFormData();
  },
  methods: {
    async fetchData() {
      const assetResponse = await AssetSvc.getAssets({ params: { max: 1000 } });
      const fuelTypesResponse = await FuelTypeSvc.getFuelType({ max: 1000 });
      const bunkerLocationResponse = await BunkerLocation.getBunkerLocation({ max: 1000 });

      this.assets = assetResponse.data.data.map((asset) => ({ value: asset.massId, text: asset.massName }));
      this.fuelTypes = fuelTypesResponse.data.map((fuelType) => ({ value: fuelType.mftyId, text: fuelType.mftyName }));
      this.bunkerLocations = bunkerLocationResponse.data.map((bunkerLocation) => ({ value: bunkerLocation.mbloId, text: bunkerLocation.mbloName }));
    },
    initializeFormData() {
      const currentOffset = moment().utcOffset();
      if (this.data.bklgId) {
        this.formData = {
          ...this.data,
          bklgStartDatetime: moment(this.data?.bklgStartDatetime).add(currentOffset, 'minutes').toDate(),
          bklgEndDatetime: moment(this.data?.bklgEndDatetime).add(currentOffset, 'minutes').toDate(),
          bklgStartSounding: this.data?.bklgStartSounding * 0.001,
          bklgEndSounding: this.data?.bklgEndSounding * 0.001,
          bklgTargetBunker: this.data?.bklgTargetBunker * 0.001,
        };
      } else {
        const bklgMftyId = this.fuelTypes.find((val) => val.text.includes('Default'))?.value;
        const bklgMbloId = this.bunkerLocations.find((val) => val.text.includes('Mantraz'))?.value;

        this.formData = {
          bklgStartDatetime: moment().toDate(),
          bklgEndDatetime: moment().add(1, 'hours').toDate(),
          bklgStartSounding: 0.00,
          bklgEndSounding: 0.00,
          bklgTargetBunker: 0.00,
          bklgMftyId,
          bklgMbloId,
        };
      }
    },
    validateFields() {
      const startDate = moment(this.formData.bklgStartDatetime);
      const endDate = moment(this.formData.bklgEndDatetime);

      // Initialize local errors object
      this.formErrors = {
        bklgStartDatetime: {},
        bklgEndDatetime: {},
        bklgStartSounding: {},
        bklgEndSounding: {},
        bklgTargetBunker: {},
        bklgMassId: {},
        bklgMftyId: {},
        bklgMbloId: {},
      };

      // Check if start and end dates are the same
      if (startDate.isSame(endDate)) {
        this.formErrors.bklgStartDatetime.same = 'Start and End Date & Time cannot be the same.';
        this.formErrors.bklgEndDatetime.same = 'Start and End Date & Time cannot be the same.';
      }

      // Check if end date is before start date
      if (endDate.isBefore(startDate)) {
        this.formErrors.bklgEndDatetime.before = 'End Date & Time cannot be earlier than Start Date & Time.';
      }

      // Check required fields
      if (!this.formData.bklgStartSounding) {
        this.formErrors.bklgStartSounding.required = 'Start Sounding is required.';
      }
      if (!this.formData.bklgEndSounding) {
        this.formErrors.bklgEndSounding.required = 'End Sounding is required.';
      }
      if (!this.formData.bklgTargetBunker) {
        this.formErrors.bklgTargetBunker.required = 'Target Bunker is required.';
      }
      if (!this.formData.bklgMassId) {
        this.formErrors.bklgMassId.required = 'Asset is required.';
      }
      if (!this.formData.bklgMftyId) {
        this.formErrors.bklgMftyId.required = 'Fuel Type is required.';
      }
      if (!this.formData.bklgMbloId) {
        this.formErrors.bklgMbloId.required = 'Bunker Location is required.';
      }

      // Emit the errors back to the parent if needed
      this.$emit('update:errors', this.formErrors);
    },

    onSubmitClicked() {
      this.validateFields(); // Call the validation method

      // Check if there are any errors
      if (Object.keys(this.formErrors.bklgStartDatetime).length > 0
        || Object.keys(this.formErrors.bklgEndDatetime).length > 0
        || Object.keys(this.formErrors.bklgStartSounding).length > 0
        || Object.keys(this.formErrors.bklgEndSounding).length > 0
        || Object.keys(this.formErrors.bklgTargetBunker).length > 0
        || Object.keys(this.formErrors.bklgMassId).length > 0
        || Object.keys(this.formErrors.bklgMftyId).length > 0
        || Object.keys(this.formErrors.bklgMbloId).length > 0) {
        return; // Prevent submission if there are validation errors
      }

      const formData = { ...this.formData };
      this.$emit('onSubmitClicked', formData);
    },
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
  },
};
</script>
