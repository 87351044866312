import axios from 'axios';

/**
 * Service untuk mengelola Config RPM
 * @namespace ConfigRPMSvc
 */
export default {
  /**
   * Mendapatkan semua data Config RPM
   * @function getConfigRPM
   * @return {Promise} Promise yang berisi response dari server
   */
  getConfigRPM: async (params) => {
    const { data } = await axios.get('/master/config_rpm', {
      params: {
        ...params,
      },
    });
    return data;
  },

  /**
   * Mendapatkan data Config RPM berdasarkan ID
   * @function getConfigRPMById
   * @param {number} id - ID dari Config RPM yang ingin di dapatkan
   * @return {Promise} Promise yang berisi response dari server
   */
  getConfigRPMById: async (id) => {
    const { data } = await axios.get(`/master/config_rpm/${id}`);
    return data;
  },

  /**
   * Membuat data Config RPM baru
   * @function createConfigRPM
   * @param {object} configRPMData - Data yang ingin di buat
   * @return {Promise} Promise yang berisi response dari server
   */
  createConfigRPM: async (configRPMData) => {
    const { data } = await axios.post('/master/config_rpm', configRPMData);
    return data;
  },

  /**
   * Mengupdate data Config RPM yang sudah ada
   * @function updateConfigRPM
   * @param {number} id - ID dari Config RPM yang ingin di update
   * @param {object} configRPMData - Data yang ingin di update
   * @return {Promise} Promise yang berisi response dari server
   */
  updateConfigRPM: async (id, configRPMData) => {
    const { data } = await axios.put(`/master/config_rpm/${id}`, configRPMData);
    return data;
  },

  /**
   * Menghapus data Config RPM yang sudah ada
   * @function deleteConfigRPM
   * @param {number} id - ID dari Config RPM yang ingin di hapus
   * @return {Promise} Promise yang berisi response dari server
   */
  deleteConfigRPM: async (id) => {
    const { data } = await axios.delete(`/master/config_rpm/${id}`);
    return data;
  },
};
