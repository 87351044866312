<template>
  <div class="iq-card mb-3" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title py-3">
        <h4 class="card-title">Asset Detail</h4>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="text-right">
            <button-options :access="canRead('master_asset')" :hideDetail="true" :isBackEnabled="true"
              :isRefreshEnabled="true" :refreshed="isLoading" @onRefreshed="getUserAssets('Refreshed')" />
          </div>
        </div>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="roleId" class="d-block form-label text-left">
            Asset:
          </label>
          <div class="bv-no-focus-ring">
            <v-select id="massId" label="massName" v-model="data.uassMassId" placeholder="Select Asset" :options="assets"
              :disabled="isSubmit" :reduce="asset => asset.massId" @search="filterAssets">
              <template slot="no-options">
                {{ assets.length > 0 ? 'Type to search Assets' : 'No assets available' }}
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <b-button class="ml-1" size="sm" type="submit" variant="primary" :disabled="isSubmit"
            @click="onSubmitClicked">
            <div class="mx-2">
              <i class="fa" :class="isSubmit ? 'ri-loader-line' : 'ri-save-line'"></i>
              Submit User Asset Data
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <b-table fixed show-empty responsive :busy="isLoading" :fields="['uassMassId', 'action']"
              :items="userAssets" head-variant="dark" style="border-radius: 10px;">
              <template v-slot:table-busy="">
                <div class="text-center text-danger my-2">
                  <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
                </div>
              </template>
              <template v-slot:head(uassMassId)="">
                <div class="text-left">
                  List of Asset
                </div>
              </template>
              <template v-slot:head(action)="">
                <div class="text-center">
                </div>
              </template>
              <template v-slot:cell(uassMassId)="data">
                {{ getAssetName(data.value) }}
              </template>
              <template v-slot:cell(action)="data">
                <div class="text-left">
                  <button class="btn btn-light m-1 btn-sm" :disabled="isSubmit" @click="openDelete(data.item)">
                    <i class="ri-delete-bin-7-line"></i>
                    Delete
                  </button>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div class="col-md-12">
          <b-pagination v-model="pagination.current" :per-page="pagination.pageSize" :total-rows="pagination.totalSize"
            @change="onRolePaginationChanged"></b-pagination>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      v-model="displayDeletion">
      <delete-item
        :isSubmit="isSubmit"
        @onCancelDeletion="displayDeletion = false"
        @onConfirmDeletion="onConfirmDeletion"/>
    </b-modal>
  </div>
</template>

<script>
import UserSvc from '@/services/UserSvc';
import requestBuilder from '@/util/request.builder';
import ButtonOptions from '@/components/directives/button-options';
import DeleteItem from '@/components/directives/delete-item';
import AssetSvc from '@/services/AssetSvc';

export default {
  name: 'UserAssetDetail',
  components: {
    ButtonOptions,
    DeleteItem,
  },
  data() {
    return {
      data: {},
      displayDeletion: false,
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      errors: {},
      filter: {
        userId: this.$route.params.id,
      },
      id: this.$route.params.id,
      selected: {},
      pagination: {
        current: 1,
        pageSize: 25,
        totalSize: 0,
      },
      assets: [],
      allAsset: [],
      userAssets: [],
      related: {
        assets: [],
      },
    };
  },
  methods: {
    async getUserAssets(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const res = await UserSvc.getUserAssets({
          params: {
            ...requestBuilder.buildFilters(this.pagination, {
              uassUserId: this.$route.params.id,
            }),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.userAssets = res.data.data;
        this.pagination.totalSize = res.data.pagination.totalData;
      } catch (e) {
        this.$toast.error('Error when fetching user assets');
        console.error('getUserAssets error: ', e);
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    async getAssets() {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const res = await AssetSvc.getAssets({
          params: {
            ...requestBuilder.buildFilters(this.pagination, {}),
          },
        });
        this.related.assets = res.data.data;
      } catch (e) {
        this.$toast.error('Error when fetching assets');
        console.error('getAssets: ', e);
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    getAssetName(id) {
      const asset = this.related.assets.find((o) => o.massId === id);
      return asset ? asset.massName : '';
    },
    getAvailableAssets() {
      this.assets = [];
      const unAvailableAssets = this.userAssets.map((o) => o.uassMassId);
      const assets = Object.values(this.related.assets);

      for (let i = 0; i < unAvailableAssets.length; i++) {
        const findIndex = assets.findIndex((o) => o.massId === unAvailableAssets[i]);
        assets.splice(findIndex, 1);
      }

      this.assets = assets;
    },
    onRolePaginationChanged(page) {
      this.pagination.current = page;
      this.getUserAssets();
    },
    // eslint-disable-next-line no-unused-vars
    async filterAssets(v, loading) {
      const param = {
        findField: 'massName',
        findValue: v,
      };
      loading = true;
      try {
        const res = await AssetSvc.getAssetByName(param);
        if (res.data.status.error === 0) {
          this.permissions = res.data.data;
        }
        loading = false;
      } catch (e) {
        loading = false;
        this.$toast.error(e.message);
      }
    },
    async onSubmitClicked() {
      const data = {
        uassUserId: this.$route.params.id,
        uassMassId: this.data.uassMassId,
      };
      this.errors = {};
      this.isSubmit = true;
      try {
        const res = await UserSvc.createUserAsset(data);
        if (res.data.status.error === 0) {
          await this.getAssets();
          await this.getUserAssets('Asset added');
          await this.getAvailableAssets();
        } else {
          const errors = res.data.status.message;
          if (typeof errors === 'object') {
            this.errors = res.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(res.data.status.message);
          }
        }
        this.pagination.current = 1;
        this.selected = {};
        this.data.usroRoleId = null;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
        console.error('onSubmitClicked: ', e);
      } finally {
        this.isSubmit = false;
      }
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    onConfirmDeletion() {
      this.deleteData('Delete complete', this.selected);
    },
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const res = await UserSvc.deleteUserAsset(data.uassId);
        if (res.data.status.error === 0) {
          this.displayDeletion = false;
          this.pagination.current = 1;
          await this.getUserAssets(notification);
          await this.getAssets();
          await this.getAvailableAssets();
        } else {
          this.$toast.error(res.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
        console.error('deleteData: ', e);
      } finally {
        this.isSubmit = false;
      }
    },
  },
  async mounted() {
    await this.getAssets();
    await this.getUserAssets();
    await this.getAvailableAssets();
  },
};
</script>
