<template>
  <div class="iq-card mb-3" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title py-3">
        <h4 class="card-title">Company Detail</h4>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="text-right">
            <button-options :access="canRead('master_user')" :hideDetail="true" :isBackEnabled="true"
              :isRefreshEnabled="true" :refreshed="isLoading" @onRefreshed="getUserCompanies('Refreshed')" />
          </div>
        </div>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="compId" class="d-block form-label text-left">
            Company:
          </label>
          <v-select
            id="compId"
            label="compName"
            placeholder="Select Company"
            v-model="data.compId"
            :class="errors.compId ? 'is-invalid' : ''"
            :disabled="isSubmit"
            :options="companies"
            :reduce="company => company.compId"
            @search="filterCompanies">
            <template slot="no-options">
              {{ companies.length > 0 ? 'Type to search company' : 'No company available' }}
            </template>
          </v-select>
          <b-form-invalid-feedback v-if="errors.compId">
            {{ Object.values(errors.compId) }}
          </b-form-invalid-feedback>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="compId" class="d-block form-label text-left">
            Have Access To All Asset:
          </label>
          <select
            id="uscoAllAssets"
            label=""
            class="form-control"
            v-model="data.uscoAllAssets"
            :disabled="isSubmit"
          >
            <option value="1" selected>Yes</option>
            <option value="0">No</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <b-button class="ml-1" size="sm" type="submit" variant="primary" :disabled="isSubmit"
            @click="onCompanySubmitClicked">
            <div class="mx-2">
              <i class="fa" :class="isSubmit ? 'ri-loader-line' : 'ri-save-line'"></i>
                Submit User Company Data
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div class="col-md-12">
          <b-table fixed show-empty responsive :busy="isLoading" :fields="['uscoCompId', 'uscoAllAssets' ,'action']"
            :items="userCompanies" head-variant="dark" style="border-radius: 10px;">
            <template v-slot:table-busy="">
              <div class="text-center text-danger my-2">
                <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
              </div>
            </template>

            <!-- head template -->
            <template v-slot:head(uscoCompId)="">
              <div class="text-left">
                List of User Company
              </div>
            </template>
            <template v-slot:head(uscoAllAssets)="">
              <div class="text-left">
                Accsess To All Assets
              </div>
            </template>
            <template v-slot:head(action)="">
              <div class="text-center">
              </div>
            </template>
            <!-- ./ head template -->

            <!-- body template -->
            <template v-slot:cell(uscoCompId)="data">
              <div class="text-left">{{ getCompanyName(data.value) }}</div>
            </template>
            <template v-slot:cell(uscoAllAssets)="data">
              <b-badge v-if="data.value === 1" variant="success">YES</b-badge>
              <b-badge v-else variant="danger">NO</b-badge>
            </template>
            <template v-slot:cell(action)="data">
              <div class="text-left">
                <button class="btn btn-light m-1 btn-sm" :disabled="isSubmit || isLoading"
                  @click="openDelete(data.item)">
                  <i class="ri-delete-bin-7-line"></i>
                  Delete
                </button>
              </div>
            </template>
            <!-- ./ body template -->
          </b-table>
        </div>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div class="col-md-12">
          <b-pagination v-model="company.pagination.current" per-page="10" :total-rows="company.pagination.totalSize"
            @change="onUserPaginationChanged"></b-pagination>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      v-model="displayDeletion">
      <delete-item
        :isSubmit="isSubmit"
        @onCancelDeletion="displayDeletion = false"
        @onConfirmDeletion="onConfirmDeletion"/>
    </b-modal>
  </div>
</template>

<script>
import ButtonOptions from '@/components/directives/button-options';
import CompanySvc from '@/services/CompanySvc';
import DeleteItem from '@/components/directives/delete-item';
import UserSvc from '@/services/UserSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'UserCompanyDetail',
  components: { ButtonOptions, DeleteItem },
  data() {
    return {
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      displayDeletion: false,
      data: {
        uscoAllAssets: 1,
      },
      errors: {},
      filter: {
        userId: this.$route.params.id,
      },
      id: this.$route.params.id,
      related: {
        companies: [],
        deleteType: undefined,
        table: {
          companies: [],
        },
      },
      selected: {},
      pagination: {
        current: 1,
        pageSize: 100,
        totalSize: 0,
      },
      company: {
        pagination: {
          current: 1,
          pageSize: 100,
          totalSize: 0,
        },
      },
      companies: [],
      userCompanies: [],
    };
  },
  methods: {
    getAvailableCompanies() {
      this.companies = [];
      const unAvailableCompanies = this.userCompanies.map((o) => o.uscoCompId);
      const companies = Object.values(this.related.companies);
      for (let i = 0; i < unAvailableCompanies.length; i++) {
        const findIndex = companies.findIndex((o) => o.compId === unAvailableCompanies[i]);
        companies.splice(findIndex, 1);
      }
      this.companies = companies;
    },
    // eslint-disable-next-line no-unused-vars
    async filterCompanies(v, loading) {
      const param = {
        findField: 'compName',
        findValue: v,
      };
      loading = true;
      try {
        const res = await CompanySvc.getCompanyByName(param);
        if (res.data.status.error === 0) {
          this.permissions = res.data.data;
        }
        loading = false;
      } catch (e) {
        loading = false;
        this.$toast.error(e.message);
      }
    },
    async getCompanies() {
      try {
        const res = await CompanySvc.getCompanies({
          params: {
            ...requestBuilder.buildFilters(this.pagination, {
              // compStatus: 1,
            }),
          },
        });
        if (res.data.status.error === 0) {
          this.related.companies = res.data.data;
        }
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
    async onCompanySubmitClicked() {
      const data = {
        uscoCompId: this.data.compId,
        uscoUserId: this.id,
        uscoAllAssets: this.data.uscoAllAssets,
      };
      this.errors = {};
      this.isSubmit = true;
      try {
        const res = await UserSvc.addUserCompany(data);
        if (res.data.status.error === 0) {
          await this.getCompanies();
          await this.getUserCompanies('Company added');
          await this.getAvailableCompanies();
        } else {
          const errors = res.data.status.message;
          if (typeof errors === 'object') {
            this.errors = res.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(res.data.status.message);
          }
        }
        this.pagination.current = 1;
        this.selected = {};
        this.data.compId = null;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getUserCompanies(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const filter = {
          uscoUserId: this.id,
        };
        const res = await UserSvc.getUserCompany({
          params: {
            ...requestBuilder.buildFilters(this.company.pagination, filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.userCompanies = res.data.data;
        this.company.pagination.totalSize = res.data.pagination.totalData;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    getCompanyName(v) {
      const comp = this.related.companies.find((o) => o.compId === v);
      return comp ? comp.compName : '';
    },
    onUserPaginationChanged(page) {
      this.company.pagination.current = page;
      this.getUserCompanies();
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    onConfirmDeletion() {
      this.deleteData('Delete complete', this.selected);
    },
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const res = await UserSvc.deleteUserCompany(data.uscoId);
        if (res.data.status.error === 0) {
          this.displayDeletion = false;
          this.pagination.current = 1;
          await this.getUserCompanies(notification);
          await this.getCompanies();
          await this.getAvailableCompanies();
        } else {
          this.$toast.error(res.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
  },
  async mounted() {
    await this.getCompanies();
    await this.getAvailableCompanies();
    await this.getUserCompanies();
  },
};
</script>
