import axios from 'axios';

export default class MasterApi {
  masterName;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    });
  }

  async getAll(params) {
    try {
      const res = await this.api.get(`/master/${this.masterName}`, { params });
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async get(id) {
    try {
      const res = await this.api.get(`/master/${this.masterName}/${id}`);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async create(data) {
    try {
      const res = await this.api.post(`/master/${this.masterName}`, data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async update(id, data) {
    try {
      const res = await this.api.put(`/master/${this.masterName}/${id}`, data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async delete(id) {
    try {
      const res = await this.api.delete(`/master/${this.masterName}/${id}`);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
