<template>
  <b-container style="padding-top: 20px">
    <div class="iq-card select-app-card" :class="{ 'desktop-app-card': !maxWidth }">
      <div class="iq-card-header">
        <div v-if="!hideHeader" class="iq-header-title w-100">
          <div class="py-3 text-center">
            <h4 class="card-title mb-2">Select App</h4>
            <h6 class="text-center">Select your App to continue.</h6>
          </div>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="select-app-wrapper">
          <button v-for="app in apps" :key="app.name" class="btn btn-block"
            :class="{'select-app-button': selectedApp !== app.name, 'select-app-button-active': selectedApp === app.name }"
            v-on:click="onSelectApp(app.name)">
            {{ app.label }}
          </button>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="pull-right mt-3">
              <button @click="onConfirmSelection" :disabled="!selectedApp" class="btn btn-primary mt-1">
                <i v-if="isLoading" class="ri-loader-line"></i>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'AppActiveSelection',
  props: ['maxWidth', 'hideHeader'],
  components: {
  },
  computed: {
  },
  mounted() {
    this.selectedApp = this.$store.state?.auth?.activeApp?.name;
  },
  data() {
    return {
      apps: [
        {
          name: 'fms',
          label: 'Fuel Monitoring System',
          routeName: 'Home',
        },
        {
          name: 'bunkering',
          label: 'Bunkering Monitoring',
          routeName: 'BunkerDashboard',
        },
      ],
      isLoading: false,
      selectedApp: undefined,
    };
  },
  methods: {
    async onConfirmSelection() {
      const selected = this.apps.find((app) => app.name === this.selectedApp);
      await this.$store.dispatch('auth/changeActiveApp', selected);
      this.$router.push({ name: selected.routeName });
      this.$bvModal.hide('select-app-modal');
    },
    onSelectApp(company) {
      this.selectedApp = company;
    },
  },
};
</script>

<style scoped>
.select-app-wrapper {
  width: 85%;
  margin: 0 auto;
  max-height: 400px;
  overflow: scroll;
}

.select-app-button {
  border-color: #E5398C;
  border-width: 2;
  color: #E5398C;
  background-color: white;
  font-weight: 500;
  font-size: 18px;
}

.select-app-button-active {
  color: white;
  border-color: #E5398C;
  border-width: 2;
  background-color: white;
  background-color: #E5398C;
  border-width: 0;
  font-weight: 500;
  font-size: 18px;
}

.select-app-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .desktop-app-card {
    max-height: 50%;
    max-width: 50%;
  }
}

</style>
