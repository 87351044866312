<template>
  <b-container fluid>
    <div class="iq-card mt-3 px-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-3">
          <h4 class="card-title">Bunkering Log</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options :access="'bunker_bunkering_log'" :hideAdd="false" :hideDetail="true"
                :isRefreshEnabled="true" :isRefreshed="isRefreshed" @onAddItemClicked="openAddForm"
                @onRefreshed="getData('Refreshed')" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <div class="d-flex align-items-center mb-3">
          <!-- date filter -->
          <div>
            <span class="d-block text-muted small text-left font-weight-bold mb-2">Select Range</span>
            <v-date-picker v-model="dateRange" is-range mode="date" :masks="masks">
              <template #default="{ inputValue, inputEvents, isDragging }">
                <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                  <div class="position-relative flex-grow-1">
                    <input class="form-control bg-light border rounded" style="width: 195px;"
                      :class="isDragging ? 'text-muted' : 'text-dark'"
                      :value="inputValue.start && inputValue.end ? inputValue.start + ' - ' + inputValue.end : 'no range selected'"
                      v-on="inputEvents.start" />
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>

          <div class="ml-3">
            <span class="d-block text-muted small text-left font-weight-bold mb-2">Range Type </span>
            <select name="" class="form-control" v-model="rangeType">
              <option value="lastMonth">Last Month</option>
              <option value="thisMonth">This Month</option>
              <option value="lastWeek">Last Week</option>
              <option value="thisWeek">This Week</option>
              <option value="yesterday">Yesterday</option>
              <option value="today">Today</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          <div class="ml-3">
            <span class="d-block text-muted small text-left font-weight-bold mb-2">Asset</span>
            <select name="" class="form-control" v-model="selectedAsset">
              <option value="all" selected>All</option>
              <option v-for="asset in assets" :key="asset.massId" :value="asset.massId">{{ asset.massName }}</option>
            </select>
          </div>

          <div class="ml-3">
            <span class="d-block text-muted small text-left font-weight-bold mb-2">Bunker Order</span>
            <select name="" class="form-control" v-model="order">
              <option value="ASC">Ascending</option>
              <option value="DESC">Descending</option>
            </select>
          </div>

          <div class="ml-3">
            <span class="d-block text-muted small text-left font-weight-bold mb-2">Bunker Order</span>
            <select name="" class="form-control" v-model="bunkerUnit">
              <option value="kl">KL</option>
              <option value="l">L</option>
            </select>
          </div>

          <div class="ml-3">
            <span class="d-block text-muted small text-left font-weight-bold mb-2">&nbsp;</span>
            <button class="btn btn-primary" @click="getData('Filter applied')">Apply</button>
          </div>

          <div class="ml-3">
            <span class="d-block text-muted small text-left font-weight-bold mb-2">&nbsp;</span>
            <button class="btn btn-info" @click="resetParams">Reset</button>
          </div>
        </div>

        <b-row>
          <b-col md="12">
            <bunkering-log-list
              :access="'bunker_bunkering_log'"
              :data="data"
              :loading="isLoading"
              :pagination="pagination"
              @onActionDelete="openDelete"
              @onActionEdit="openEdit"
              @onActionView="openDetail"
              @onPageChanged="onPageChanged"
              @update="onUpdated"
              :bunkerUnit="bunkerUnit"
            />
          </b-col>
        </b-row>
      </div>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayForm">
        <bunkering-log-form :data="currentBunkeringLog" :errors="errors" :isSubmit="isSubmit"
          @onSubmitClicked="onSubmitClicked" @onCancelAddClicked="onCancel" />
      </b-modal>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayDeletion">
        <delete-item :isSubmit="isSubmit" @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import moment from 'moment';

import DeleteItem from '@/components/directives/delete-item';
import ButtonOptions from '@/components/directives/button-options';
import BunkeringLogSvc from '@/services/BunkeringLogSvc';
import AssetSvc from '@/services/AssetSvc';
import BunkeringLogList from '@/components/bunkering_log/List';
import BunkeringLogForm from '@/components/bunkering_log/Form';

export default {
  name: 'MasterBunkeringLog',
  components: {
    DeleteItem, BunkeringLogForm, BunkeringLogList, ButtonOptions,
  },
  data() {
    return {
      currentBunkeringLog: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
      masks: {
        input: 'DD-MM-YYYY',
      },
      dateRange: {
        start: moment().startOf('month').toDate(),
        end: moment().endOf('day').toDate(),
      },
      rangeType: 'thisMonth',
      assets: [],
      selectedAsset: 'all',
      order: 'DESC',
      bunkerUnit: 'kl',
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const deleteBunkeringLogResponse = await BunkeringLogSvc.deleteBunkeringLog(data.bklgId);
        if (deleteBunkeringLogResponse.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(deleteBunkeringLogResponse.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getAssets() {
      try {
        const assetsResponse = await AssetSvc.getAssets();
        this.assets = assetsResponse.data.data;
      } catch (e) {
        console.error('get assets error : ', e);
      }
    },
    resetParams() {
      this.selectedAsset = null;
      this.dateRange = { start: moment().startOf('month').toDate(), end: moment().endOf('day').toDate() };
      this.getData('Filter Reset');
    },
    async getData(notification) {
      try {
        this.isLoading = true;
        this.isRefreshed = true;

        let params = {};
        let bunkeringLogData = [];

        params = {};
        const startDate = moment(this.dateRange.start).startOf('day').unix();
        const endDate = moment(this.dateRange.end).endOf('day').unix();

        if (!this.selectedAsset || this.selectedAsset === 'all') {
          params['finds[bklgMassId]'] = this.assets.map((asset) => asset.massId);
        } else {
          params['finds[bklgMassId][]'] = this.selectedAsset;
        }

        const bunkeringLogResponse = await BunkeringLogSvc.getBunkeringLogByPeriod(startDate, endDate, params);
        bunkeringLogData = bunkeringLogResponse.data;

        /**
         * api period tidak mendukung order by jadi harus di sort sendiri
         */
        if (this.order === 'ASC') {
          bunkeringLogData = bunkeringLogData.sort((a, b) => moment(a.bklgStartDatetime).unix() - moment(b.bklgStartDatetime).unix());
        } else {
          bunkeringLogData = bunkeringLogData.sort((a, b) => moment(b.bklgStartDatetime).unix() - moment(a.bklgStartDatetime).unix());
        }

        this.data = bunkeringLogData;

        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        console.error('get data error : ', e);
        this.$toast.error('Something Wrong');
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.currentBunkeringLog = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Bunker Location Deleted', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    async onSubmitClicked(form) {
      try {
        this.errors = {};
        this.isSubmit = true;

        const bunkeringLogData = {
          bklgMassId: form.bklgMassId,
          bklgStartDatetime: moment(form.bklgStartDatetime).utc().format('YYYY-MM-DD HH:mm:ss'),
          bklgEndDatetime: moment(form.bklgEndDatetime).utc().format('YYYY-MM-DD HH:mm:ss'),
          bklgMbloId: form.bklgMbloId,
          bklgMftyId: form.bklgMftyId,
          bklgStartSounding: form.bklgStartSounding * 1000,
          bklgEndSounding: form.bklgEndSounding * 1000,
          bklgTargetBunker: form.bklgTargetBunker * 1000,
        };

        if (!form.bklgId) { // jika tidak ada bklgId maka create
          const createBunkeringLogResponse = await BunkeringLogSvc.createBunkeringLog(bunkeringLogData);

          if (createBunkeringLogResponse.status.error === 0) {
            await this.getData('Success');
            this.currentBunkeringLog = {};
          } else {
            this.$toast.error('Error when saving data');
          }
        } else { // jika ada bklgId maka update
          const updateBunkeringLogResponse = await BunkeringLogSvc.updateBunkeringLog(form.bklgId, bunkeringLogData);

          if (updateBunkeringLogResponse.status.error === 0) {
            await this.getData('Success');
            this.currentBunkeringLog = {};
          } else {
            this.$toast.error('Error when Update data');
          }
        }
      } catch (e) {
        console.error('onSubmitClicked error : ', e);
        this.$toast.error('Error When Submiting Form');
      } finally {
        this.displayForm = false;
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.currentBunkeringLog = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(data) {
      try {
        const start = moment(data?.bklgStartDatetime).utc(true);
        const end = moment(data?.bklgEndDatetime).utc(true);

        this.$store.dispatch('filter/setCurrentMassId', data?.bklgMassId);
        this.$store.dispatch('filter/setInterval', 'minute');
        this.$store.commit('filter/SET_DATE', {
          start,
          end,
        });

        this.$router.push({ name: 'BunkerDashboard' });
      } catch (e) {
        console.error('openDetail error : ', e);
        this.$toast.error('Error When Open Detail');
      }
    },
    openEdit(data) {
      this.displayForm = true;
      this.currentBunkeringLog = { ...this._.find(this.data, (v) => v.bklgId === data.bklgId) };
    },
  },
  async mounted() {
    await this.getAssets();
    await this.getData();
  },
  watch: {
    rangeType() {
      switch (this.rangeType) {
        case 'today':
          this.dateRange = { start: moment().startOf('day').toDate(), end: moment().toDate() };
          break;
        case 'yesterday':
          this.dateRange = { start: moment().subtract(1, 'day').startOf('day').toDate(), end: moment().subtract(1, 'day').endOf('day').toDate() };
          break;
        case 'thisWeek':
          this.dateRange = { start: moment().startOf('week').toDate(), end: moment().toDate() };
          break;
        case 'lastWeek':
          this.dateRange = { start: moment().subtract(1, 'week').startOf('week').toDate(), end: moment().subtract(1, 'week').endOf('week').toDate() };
          break;
        case 'thisMonth':
          this.dateRange = { start: moment().startOf('month').toDate(), end: moment().toDate() };
          break;
        case 'lastMonth':
          this.dateRange = { start: moment().subtract(1, 'month').startOf('month').toDate(), end: moment().subtract(1, 'month').endOf('month').toDate() };
          break;
        default:
          break;
      }
    },
  },
};
</script>
