<template>
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title my-2">
          <h4 class="card-title">Asset List</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options
                :access="'master_asset'"
                :hideAdd="false"
                :hideDetail="true"
                :isRefreshEnabled="true"
                :isRefreshed="isLoading"
                @onAddItemClicked="openAddForm"
                @onRefreshed="getData('Refreshed')"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row v-if="isLoading">
          <b-col md="12">
            <div class="text-center">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col md="4" sm="12" v-for="(asset, index) in data" v-bind:key="`E-${index}`" class="my-3">
            <div class="iq-card shadow" style="min-height: 240px">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title py-2">
                  <h5 class="card-title">{{ asset.massName }}</h5>
                </div>
              </div>
              <div class="iq-card-body px-0">
                <img
                  :src=" asset.massImage !== '' ? '/api/' + asset.massImage : ''"
                  :alt="asset.massName + ' image'"
                  class="img-fluid"
                  style="
                    min-height: 200px;
                    max-height: 200px;
                    width: 100%;
                    object-fit: cover;
                    margin: 0 auto;
                  "
                />
                <div class="d-flex flex-column flex-md-row justify-content-between px-3 mt-2">
                  <div class="mb-2 mb-md-0">
                    <span class="text-muted small">Installation Type</span>
                    <h5 class="mt-1 font-weight-bold">{{ asset.massSystemType }}</h5>
                  </div>
                  <div class="mb-2 mb-md-0">
                    <span class="text-muted small">Asset Type</span>
                    <h5 class="mt-1 font-weight-bold">{{ asset.massMaty.matyName }}</h5>
                  </div>
                  <div class="pt-4 pt-md-0">
                    <button class="btn btn-outline-secondary btn-sm mr-1" @click="openDevices(asset.massId)">
                      <i class="ri-eye-line"></i>
                      See Device
                    </button>
                    <button class="btn btn-outline-danger btn-sm mr-1" @click="openDelete(asset)">
                      <i class="ri-delete-bin-2-line"></i>
                    </button>
                    <button class="btn btn-outline-primary btn-sm" @click="openEditForm(asset.massId)">
                      <i class="ri-edit-2-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- Untuk sementara di komentari untuk referensi hehe -->
        <!-- <b-row>
          <b-col md="12">
            <asset-list
              @onActionDelete="openDelete"
              @onActionEdit="openEditForm"
              @onActionView="openDetail"
              @onActionAnalytics="openAnalytics"
              @onPaginationChange="onPaginationChange"
              :access="'master_asset'"
              :data="data"
              :isLoading="isLoading"
              :pagination="pagination"
            />
          </b-col>
        </b-row> -->

      </div>

      <!-- create/update modal -->
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayForm"
      >
        <asset-form
          :data="currentAsset"
          :assetTypes="assetType"
          :fields="fields"
          @onSuccess="onAddOrUpdateSuccess"
          @onCancel="onCancel"
        />
      </b-modal>
      <!-- ./create/update modal -->

      <!-- delete modal -->
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayDeletion"
      >
        <DeleteItem
          :isSubmit="isSubmit"
          @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion"
        />
      </b-modal>
      <!-- ./delete modal -->
    </div>
  </b-container>
</template>

<script>
import AssetForm from '@/components/asset/Form.vue';
// import AssetList from '@/components/asset/List.vue';
import AssetSvc from '@/services/AssetSvc';
import AssetTypeSvc from '@/services/AssetTypeSvc';
import ButtonOptions from '@/components/directives/button-options';
import DeleteItem from '@/components/directives/delete-item';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'Asset',
  data() {
    return {
      currentAsset: {},
      assetType: [],
      data: [],
      displayForm: false,
      displayDeletion: false,
      filter: {
        massType: null,
      },
      isLoading: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
      fields: {},
    };
  },
  components: {
    DeleteItem,
    ButtonOptions,
    AssetForm,
    // AssetList,
  },
  methods: {
    async deleteData(notification, massId) {
      this.isSubmit = true;
      try {
        const resp = await AssetSvc.deleteAsset(massId);
        if (resp.data.status.error === 0) {
          this.$toast.success(notification);
          this.displayDeletion = false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(resp.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData() {
      try {
        this.isLoading = true;
        const assetResponse = await AssetSvc.getAssets({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });

        const mappedFields = Object.values(assetResponse.data.fields).filter(
          (field) => {
            const isHide = [
              'massId',
              'massCompId',
              'massDataId',
              'massType',
              'massFileId',
              'massSatDeviceId',
              'massCreatedUserId',
              'massCreatedTime',
              'massUpdatedUserId',
              'massUpdatedTime',
            ];
            return !isHide.includes(field.Field);
          },
        ).map((field) => {
          // Jika field.Field adalah 'massMatyId', ganti labelnya menjadi 'Asset Type'
          if (field.Field === 'massMatyId') {
            return {
              ...field,
              Label: 'Asset Type',
            };
          }
          return field; // Kembali ke field tanpa perubahan
        });

        this.data = assetResponse.data.data;
        this.fields = mappedFields;
        this.pagination.totalSize = assetResponse.data.pagination.totalData;
      } catch (e) {
        console.error('getData: ', e);
      } finally {
        this.isLoading = false;
      }
    },
    onAddOrUpdateSuccess() {
      this.getData();
      this.displayForm = false;
    },
    onCancel() {
      this.currentAsset = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Deleted complete', this.selected.massId);
    },
    onPaginationChange(page) {
      this.pagination.current = page;
      this.getData();
    },
    openAddForm() {
      this.currentAsset = {};
      this.displayForm = true;
    },
    openAnalytics(massId) {
      this.$router.push({ name: 'AssetAnalytics', params: { id: massId } });
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(massId) {
      this.$router.push({ name: 'AssetDetail', params: { id: massId } });
    },

    openEditForm(massId) {
      // Mencari objek yang cocok dengan massId
      const foundAsset = this._.find(this.data, (v) => v.massId === massId);
      // Jika ditemukan, hapus key massMaty
      if (foundAsset) {
        // Membuat salinan dari foundAsset dan menghapus massMaty
        const { massMaty, ...currentAsset } = foundAsset; // Menggunakan destructuring untuk menghapus massMaty
        this.currentAsset = currentAsset; // Mengassign hasil ke this.currentAsset
      }

      this.displayForm = true;
    },
    openDevices(massId) {
      this.$router.push({ name: 'AssetDevice', params: { id: massId } });
    },
  },
  async beforeMount() {
    this.getData();

    const assetTypesResponse = await AssetTypeSvc.getAssetType({ pageSize: 100 });
    const assetTypesData = assetTypesResponse.data;

    this.assetType = assetTypesData.map((assetType) => ({
      value: assetType.matyId,
      text: assetType.matyName,
    }));
  },
};
</script>
