<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="iq-card mb-0" style="min-width: 300px;">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="!data.userCreatedTime">Add Config AE</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row mb-3">
        <div role="group" class="form-group col-md-6">
          <label for="mcaeDevcId" class="d-block form-label text-left">
            Device ID:
          </label>
          <b-form-select
            class="form-control"
            v-model="data.mcaeDevcId"
            :options="devices"
            :class="errors.mcaeDevcId ? 'is-invalid' : ''"
          >
            <template v-slot:first>
              <option :value="null" disabled>Select Device</option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback v-if="errors.mcaeDevcId">
            {{ Object.values(errors.mcaeDevcId) }}
          </b-form-invalid-feedback>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="mcaeNo" class="d-block form-label text-left">
            Number:
          </label>
          <input
            class="form-control"
            type="number"
            id="mcaeNo"
            placeholder="Number"
            v-model="data.mcaeNo"
            :class="errors.mcaeNo ? 'is-invalid' : ''"
          >
          <b-form-invalid-feedback v-if="errors.mcaeNo">
            {{ Object.values(errors.mcaeNo) }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row mb-3">
        <div role="group" class="form-group col-md-6">
          <label for="mcaeConsumptRateByTime" class="d-block form-label text-left">
            Consumption Rate by Time:
          </label>
          <input
            class="form-control"
            type="number"
            step="0.1"
            id="mcaeConsumptRateByTime"
            placeholder="Consumption Rate by Time"
            v-model="data.mcaeConsumptRateByTime"
            :class="errors.mcaeConsumptRateByTime ? 'is-invalid' : ''"
          >
          <b-form-invalid-feedback v-if="errors.mcaeConsumptRateByTime">
            {{ Object.values(errors.mcaeConsumptRateByTime) }}
          </b-form-invalid-feedback>
        </div>

        <div role="group" class="form-group col-md-6">
          <label for="mcaeConsumptRateByPower" class="d-block form-label text-left">
            Consumption Rate by Power:
          </label>
          <input
            class="form-control"
            type="number"
            step="0.1"
            id="mcaeConsumptRateByPower"
            placeholder="Consumption Rate by Power"
            v-model="data.mcaeConsumptRateByPower"
            :class="errors.mcaeConsumptRateByPower ? 'is-invalid' : ''"
          >
          <b-form-invalid-feedback v-if="errors.mcaeConsumptRateByPower">
            {{ Object.values(errors.mcaeConsumptRateByPower) }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row mb-3">
        <div role="group" class="form-group col-md-6">
          <label for="mcaeConsumptRateByRpm" class="d-block form-label text-left">
            Consumption Rate by RPM:
          </label>
          <input
            class="form-control"
            type="number"
            step="0.1"
            id="mcaeConsumptRateByRpm"
            placeholder="Consumption Rate by RPM"
            v-model="data.mcaeConsumptRateByRpm"
            :class="errors.mcaeConsumptRateByRpm ? 'is-invalid' : ''"
          >
          <b-form-invalid-feedback v-if="errors.mcaeConsumptRateByRpm">
            {{ Object.values(errors.mcaeConsumptRateByRpm) }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="form-group">
        <b-button
          @click="onCancelAddClicked"
          class="mr-1"
          size="sm"
          :disabled="isSubmit"
          variant="outline-danger"
        >
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button
          type="submit"
          @click="onSubmitClicked"
          :disabled="isSubmit"
          variant="primary"
          size="sm"
          class="ml-1"
        >
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceSvc from '../../../services/DeviceSvc';

export default {
  name: 'MasterConfigAeForm',
  props: ['data', 'errors', 'isSubmit'],
  data() {
    return {
      devices: [],
    };
  },
  mounted() {
    this.fetchDeviceData();
  },
  methods: {
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const data = { ...this.data };
      this.$emit('onSubmitClicked', data);
    },
    async fetchDeviceData() {
      try {
        const response = await DeviceSvc.getDevices({ params: { max: 1000 } });
        const devices = response.data.data;

        this.devices = devices.filter((device) => device.devcType === 'ae').map((device) => ({
          text: device.devcUniqueId,
          value: device.devcId,
        }));
      } catch (e) {
        console.error(e);
        this.$toast.error('error when fetching device data');
      }
    },
  },
};
</script>
