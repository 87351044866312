<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="iq-card mb-0" style="min-width: 300px">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title" v-if="data.devcId">Edit Device</h4>
        <h4 class="card-title" v-if="!data.devcId">Add New Device</h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="devcName" class="d-block form-label text-left">
            Device:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                id="devcName"
                autocomplete="off"
                v-model="data.devcName"
                placeholder="Enter name"
                type="text"
                :class="errors.devcName ? 'is-invalid' : ''"
                class="form-control"
              />
              <b-form-invalid-feedback v-if="errors.devcName">
                {{ Object.values(errors.devcName) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label class="d-block form-label text-left">
            Device Unique Id:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                id="devcUniqueId"
                autocomplete="off"
                placeholder="Enter unique id"
                type="text"
                v-model="data.devcUniqueId"
                :class="errors.devcUniqueId ? 'is-invalid' : ''"
                class="form-control"
              />
              <b-form-invalid-feedback v-if="errors.devcUniqueId">
                {{ Object.values(errors.devcUniqueId) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label class="d-block form-label text-left"> Device Type: </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <b-form-select
                v-model="data.devcType"
                text-field="name"
                value-field="value"
                class="form-control"
                style="
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                "
                :options="related.devcTypes"
              >
                <template v-slot:first="">
                  <b-form-select-option :value="undefined" disabled>
                    -- Please Select Type --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback v-if="errors.devcType">
                {{ Object.values(errors.devcType) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label class="d-block form-label text-left"> Status: </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <select
                class="form-control"
                v-model="data.devcStatus"
                :disabled="isSubmit"
                :class="errors.devcStatus ? 'is-invalid' : ''"
              >
                <option disabled="disabled" :value="undefined">
                  -- Please Select Status --
                </option>
                <option value="0">Not Approved</option>
                <option value="1">Approved</option>
              </select>
            </div>
            <b-form-invalid-feedback v-if="errors.devcStatus">
              {{ Object.values(errors.devcStatus) }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label class="d-block form-label text-left"> Gateway: </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                id="devcGateway"
                autocomplete="off"
                placeholder="Enter gateway"
                type="text"
                :class="errors.devcGateway ? 'is-invalid' : ''"
                class="form-control"
              />
              <b-form-invalid-feedback v-if="errors.devcGateway">
                {{ Object.values(errors.devcGateway) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div role="group" class="form-group col-md-6">
          <label for="devcName" class="d-block form-label text-left">
            Label:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <input
                id="devcLabel"
                autocomplete="off"
                v-model="data.devcLabel"
                placeholder="Enter label"
                type="text"
                :class="errors.devcLabel ? 'is-invalid' : ''"
                class="form-control"
              />
              <b-form-invalid-feedback v-if="errors.devcLabel">
                {{ Object.values(errors.devcLabel) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- pemilihan asset hilang jika ada massId dari param -->
        <div role="group" class="form-group col-md-6" v-if="!massId">
          <label for="devcMassId" class="d-block form-label text-left">
            Link to Asset:
          </label>
          <v-select
            id="devcMassId"
            label="massName"
            v-model="data.devcMassId"
            placeholder="Select Asset"
            :options="related.assets"
            :disabled="isSubmit || massId"
            :reduce="(asset) => asset.massId"
            :class="errors.devcMassId ? 'is-invalid' : ''"
            @search="getData"
          >
            <template slot="no-options"> Type to search asset </template>
          </v-select>
          <b-form-invalid-feedback v-if="errors.devcMassId">
            {{ Object.values(errors.devcMassId) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="row">
        <div role="group" class="form-group col-md-12">
          <label class="d-block form-label text-left"> Description: </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <textarea
                id="devcDescription"
                autocomplete="off"
                v-model="data.devcDescription"
                placeholder="Enter description"
                type="text"
                :class="errors.devcDescription ? 'is-invalid' : ''"
                class="form-control"
              ></textarea>
              <b-form-invalid-feedback v-if="errors.devcDescription">
                {{ Object.values(errors.devcDescription) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <b-button
          @click="onCancelAddClicked"
          class="mr-1"
          size="sm"
          :disabled="isSubmit"
          variant="outline-danger"
        >
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button
          type="submit"
          @click="onSubmitClicked"
          :disabled="isSubmit"
          variant="primary"
          size="sm"
          class="ml-1"
        >
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import AssetSvc from '../../services/AssetSvc';

export default {
  name: 'AssetForm',
  computed: {},
  props: ['data', 'errors', 'isSubmit'],
  data() {
    return {
      massId: this.$route.params.id || null,
      related: {
        assets: [],
        devcTypes: [
          { name: 'GPS', value: 'gps' },
          { name: 'Flowmeter', value: 'flowmeter' },
          { name: 'RPM', value: 'rpm' },
          { name: 'Auxialary Engine', value: 'ae' },
          { name: 'Gateway', value: 'gateway' },
          { name: 'Power Supply', value: 'power' },
          { name: 'Tank', value: 'tank' },
          { name: 'Temperature', value: 'Temperature' },
        ],
      },
      options: [
        { value: 0, text: 'Not Approved' },
        { value: 1, text: 'Approved' },
      ],
    };
  },
  methods: {
    async getData() {
      try {
        const asset = await AssetSvc.getAssets();
        this.related.assets = asset.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    onCancelAddClicked() {
      this.$emit('onCancelAddClicked');
    },
    onSubmitClicked() {
      const data = { ...this.data };
      this.$emit('onSubmitClicked', data);
    },
    // eslint-disable-next-line no-unused-vars
    getAssets(v, loading) {
      setTimeout(async () => {
        loading = true;
        const param = {
          findField: 'massName',
          findValue: v,
        };
        try {
          const res = await AssetSvc.getAssetByName(param);
          if (res.data.status.error === 0) {
            this.related.assets = res.data.data;
          }
          loading = false;
        } catch (e) {
          loading = false;
          this.$toast.error(e.message);
        }
      });
    },
  },
  mounted() {
    this.getData();
    if (this.massId) {
      this.data.devcMassId = this.massId;
    }
  },
};
</script>

<style scoped></style>
