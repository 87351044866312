import axios from 'axios';

/**
 * Service untuk mengelola Config AE
 * @namespace ConfigAESvc
 */
export default {
  /**
   * Mendapatkan semua data Config AE
   * @function getConfigAE
   * @return {Promise} Promise yang berisi response dari server
   */
  getConfigAE: async (params) => {
    const { data } = await axios.get('/master/config_ae', {
      params: {
        ...params,
      },
    });
    return data;
  },

  /**
   * Mendapatkan data Config AE berdasarkan ID
   * @function getConfigAEById
   * @param {number} id - ID dari Config AE yang ingin di dapatkan
   * @return {Promise} Promise yang berisi response dari server
   */
  getConfigAEById: async (id) => {
    const { data } = await axios.get(`/master/config_ae/${id}`);
    return data;
  },

  /**
   * Membuat data Config AE baru
   * @function createConfigAE
   * @param {object} configAEData - Data yang ingin di buat
   * @return {Promise} Promise yang berisi response dari server
   */
  createConfigAE: async (configAEData) => {
    const { data } = await axios.post('/master/config_ae', configAEData);
    return data;
  },

  /**
   * Mengupdate data Config AE yang sudah ada
   * @function updateConfigAE
   * @param {number} id - ID dari Config AE yang ingin di update
   * @param {object} configAEData - Data yang ingin di update
   * @return {Promise} Promise yang berisi response dari server
   */
  updateConfigAE: async (id, configAEData) => {
    const { data } = await axios.put(`/master/config_ae/${id}`, configAEData);
    return data;
  },

  /**
   * Menghapus data Config AE yang sudah ada
   * @function deleteConfigAE
   * @param {number} id - ID dari Config AE yang ingin di hapus
   * @return {Promise} Promise yang berisi response dari server
   */
  deleteConfigAE: async (id) => {
    const { data } = await axios.delete(`/master/config_ae/${id}`);
    return data;
  },
};
