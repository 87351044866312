<template>
  <b-container fluid>
    <div class="iq-card mt-3 px-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-3">
          <h4 class="card-title">Config Tank</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right my-2">
              <button-options :access="'master_config_tank'" :hideAdd="false" :hideDetail="true"
                :isRefreshEnabled="true" :isRefreshed="isRefreshed" @onAddItemClicked="openAddForm"
                @onRefreshed="getData('Refreshed')" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col sm="12" md="4">
            <label for="" class="m-0">Devices</label>
            <b-form-select v-model="filter.mcotDeviceId" :options="devices" @change="getData('Refreshed')" class="mb-2"
              size="sm" placeholder="Select Company" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div class="table-responsive">
              <b-table striped hover fixed head-variant="dark" show-empty responsive :busy="isLoading" :fields="fields"
                :items="canRead('master_config_tank') ? data : []"
                style="border-radius: 10px; overflow: scroll; max-height: 500px;">
                <template v-slot:table-busy="">
                  <div class="text-center text-danger my-2">
                    <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <div class="text-center">
                    <button-options :access="'master_config_tank'" :hideAdd="true" :hideDelete="false"
                      :hideDetail="true" :hideEdit="false" @onViewClicked="openDetail(data.item)"
                      @onDeleteClicked="openDelete(data.item)" @onEditClicked="openEdit(data.item)" />
                  </div>
                </template>
              </b-table>

              <b-pagination v-model="pagination.current" :per-page="pagination.pageSize"
                :total-rows="pagination.totalSize" @change="onPageChanged"></b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayForm">
        <div class="iq-card mb-0" style="min-width: 300px;">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title" v-if="!current.mcotId">Add New Config Tank</h4>
              <h4 class="card-title" v-if="current.mcotId">Edit Config Tank</h4>
            </div>
          </div>
          <div class="iq-card-body">
            <div class="row mb-3">
              <div role="group" class="form-group col-md-6">
                <label for="mcotDeviceId" class="d-block form-label text-left">
                  Device:
                </label>
                <div class="bv-no-focus-ring">
                  <div role="group" class="input-group">
                    <b-form-select v-model="current.mcotDeviceId" :options="devices"
                      :class="errors.mcotDeviceId ? 'is-invalid' : ''"></b-form-select>
                    <b-form-invalid-feedback v-if="errors.mcotDeviceId">
                      {{ Object.values(errors.mcotDeviceId) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>

              <div role="group" class="form-group col-md-6">
                <label for="mcotNo" class="d-block form-label text-left">
                  Tank Number:
                </label>
                <div class="bv-no-focus-ring">
                  <div role="group" class="input-group">
                    <b-form-input required class="form-control" type="text" id="mcotNo" placeholder="Tank Number"
                      v-model="current.mcotNo" :class="errors.mcotNo ? 'is-invalid' : ''" />
                    <b-form-invalid-feedback v-if="errors.mcotNo">
                      {{ Object.values(errors.mcotNo) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>

              <div role="group" class="form-group col-md-6">
                <label for="userId" class="d-block form-label text-left">
                  Sensor Category:
                </label>
                <div class="bv-no-focus-ring">
                  <div role="group" class="input-group">
                    <b-form-select v-model="current.mcotMscaId" :options="sensorCategories"
                      :class="errors.mcotMscaId ? 'is-invalid' : ''"></b-form-select>
                    <b-form-invalid-feedback v-if="errors.mcotMscaId">
                      {{ Object.values(errors.mcotMscaId) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>

              <div role="group" class="form-group col-md-6">
                <label for="mcotTahdId" class="d-block form-label text-left">
                  Tank Headers:
                </label>
                <div class="bv-no-focus-ring">
                  <div role="group" class="input-group">
                    <b-form-select v-model="current.mcotTahdId" :options="tankHeaders"
                      :class="errors.mcotTahdId ? 'is-invalid' : ''"></b-form-select>
                    <b-form-invalid-feedback v-if="errors.mcotTahdId">
                      {{ Object.values(errors.mcotTahdId) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <b-button @click="onCancel" class="mr-1" size="sm" :disabled="isSubmit" variant="outline-danger">
                <div class="mx-2">Cancel</div>
              </b-button>
              <b-button @click="onSubmitClicked" :disabled="isSubmit" variant="primary" size="sm" class="ml-1">
                <div class="mx-2">
                  <i v-if="isSubmit" class="ri-loader-line"></i>
                  Submit
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayDeletion">
        <delete-item :isSubmit="isSubmit" @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import DeleteItem from '@/components/directives/delete-item';
import ButtonOptions from '@/components/directives/button-options';
import TankHeaderSvc from '@/services/TankHeaderSvc';
import ConfigTankSvc from '@/services/config/TankSvc';
import SensorCategorySvc from '@/services/SensorCategorySvc';
import DeviceSvc from '@/services/DeviceSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'MasterConfigTank',
  components: {
    DeleteItem, ButtonOptions,
  },
  data() {
    return {
      ConfigTank: new ConfigTankSvc(),
      TankHeader: new TankHeaderSvc(),
      SensorCategory: new SensorCategorySvc(),
      tankHeaders: [],
      sensorCategories: [],
      devices: [],
      current: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
      filter: {},
      selected: {
        mcotDeviceId: null,
      },
      fields: [
        {
          key: 'mcotDevice.devcUniqueId',
          label: 'Device Unique ID',
          class: 'text-left',
        },
        {
          key: 'mcotNo',
          label: 'Tank Number',
          class: 'text-left',
        },
        {
          key: 'mcotTahd.tahdName',
          label: 'Tank Header Name',
          class: 'text-left',
        },
        {
          key: 'mcotMsca.mscaName',
          label: 'Sensor Category',
          class: 'text-left',
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
        },
      ],
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const deleteResponse = await this.ConfigTank.delete(data.mcotId);
        if (deleteResponse.status.error === 0) {
          this.$toast.success(notification);
          this.displayDeletion = false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(deleteResponse.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      try {
        this.isLoading = true;
        this.isRefreshed = true;

        const ConfigTankResponse = await this.ConfigTank.getAll({
          ...requestBuilder.buildFilters(this.pagination, this.filter),
        });

        const Data = ConfigTankResponse.data;

        this.data = Data;

        this.pagination.totalSize = ConfigTankResponse.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        console.error('get data error : ', e);
        this.$toast.error('Something wrong when fetching data');
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.current = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Sensor Type Deleted', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    async onSubmitClicked() {
      try {
        this.errors = {};
        this.isSubmit = true;

        const Data = {
          mcotDeviceId: this.current.mcotDeviceId,
          mcotNo: this.current.mcotNo,
          mcotMscaId: this.current.mcotMscaId,
          mcotTahdId: this.current.mcotTahdId,
        };

        if (Data.mcotDeviceId === null || Data.mcotDeviceId === undefined) {
          this.errors.mcotDeviceId = ['Device is required'];
        }

        if (Data.mcotNo === null || Data.mcotNo === undefined) {
          this.errors.mcotNo = ['Tank Number is required'];
        }

        if (Data.mcotMscaId === null || Data.mcotMscaId === undefined) {
          this.errors.mcotMscaId = ['Sensor Category is required'];
        }

        if (Data.mcotTahdId === null || Data.mcotTahdId === undefined) {
          this.errors.mcotTahdId = ['Tank Header is required'];
        }

        if (Object.keys(this.errors).length > 0) {
          return;
        }

        if (!this.current.mcotId) { // jika tidak ada mcotId maka create
          const createResponse = await this.ConfigTank.create(Data);

          if (createResponse.status.error === 0) {
            await this.getData('Success');
            this.current = {};
          } else {
            this.$toast.error('Error when saving data');
          }
        } else { // jika ada mcotId maka update
          const updateResponse = await this.ConfigTank.update(this.current.mcotId, Data);

          if (updateResponse.status.error === 0) {
            await this.getData('Success');
            this.current = {};
          } else {
            this.$toast.error('Error when Update data');
          }
        }
        this.displayForm = false;
      } catch (e) {
        console.error('onSubmitClicked error : ', e);
        this.displayForm = false;
        this.$toast.error('Error when submiting data');
      } finally {
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.current = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openEdit(data) {
      this.displayForm = true;
      this.current = { ...this._.find(this.data, (v) => v.mcotId === data.mcotId) };
    },
  },
  async mounted() {
    this.getData();
    const [TankHeaderResponse, SensorCategoryResponse, DeviceResponse] = await Promise.all([
      this.TankHeader.getAll({ max: 1000 }),
      this.SensorCategory.getAll({ max: 1000 }),
      DeviceSvc.getDevices({ params: { max: 1000 } }),
    ]);

    this.tankHeaders = TankHeaderResponse.data.map((comp) => ({ value: comp.tahdId, text: comp.tahdName }));
    this.sensorCategories = SensorCategoryResponse.data.map((cat) => ({ value: cat.mscaId, text: cat.mscaName }));
    this.devices = DeviceResponse.data.data.filter((dev) => dev.devcType === 'tank').map((dev) => ({
      text: dev.devcUniqueId,
      value: dev.devcId,
    }));
  },
};
</script>
