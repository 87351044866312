<template>
  <b-dropdown
    id="download-report-dropdown"
    ref="report-dropdown"
    variant="success"
    class="my-2"
    :text=" isLoading ? 'Downloading...' : ' Download Report'"
    :disabled="isLoading"
  >
    <b-dropdown-form class="px-1">
      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="summary" v-model="selected.summary">
          <label class="custom-control-label" for="summary">Summary</label>
        </div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="vessel-tracking" v-model="selected.vessel_tracking">
          <label class="custom-control-label" for="vessel-tracking">Vessel Tracking</label>
        </div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="rpm-speed-ae" v-model="selected.rpm_speed">
          <label class="custom-control-label" for="rpm-speed-ae">RPM Speed AE</label>
        </div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="fuel-characteristics" v-model="selected.fuel_characteristics">
          <label class="custom-control-label" for="fuel-characteristics">Fuel Characteristics</label>
        </div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="ae-usage" v-model="selected.ae_usage">
          <label class="custom-control-label" for="ae-usage">AE Usage</label>
        </div>
      </div>
    </b-dropdown-form>

    <b-dropdown-divider></b-dropdown-divider>
    <div class="mx-1">
      <b-button variant="primary" block class="mb-1" size="sm" @click="download">Download</b-button>
      <b-button variant="primary" block class="mb-1" size="sm" @click="downloadAll">Download All</b-button>
    </div>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import ReportSvc from '@/services/ReportSvc'; // Pastikan path ini sesuai dengan struktur proyek Anda

export default {
  name: 'ReportDownloadDropdown',
  data() {
    return {
      selected: {
        summary: false,
        vessel_tracking: false,
        rpm_speed: false,
        fuel_characteristics: false,
        ae_usage: false,
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('filter', ['date', 'currentMassId']),
  },
  methods: {
    async download() {
      this.isLoading = true;

      const selected = this.selected;
      const selectedKeys = Object.keys(selected).filter((key) => selected[key]);

      if (selectedKeys.length > 0) {
        const start = this.date.start.unix();
        const end = this.date.end.unix();
        const currentMassId = this.currentMassId;

        try {
          const { blob, filename } = await ReportSvc.getReports(start, end, currentMassId, selectedKeys);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading report:', error);
        } finally {
          this.isLoading = false;
        }
      } else {
        console.alert('No options selected');
        this.isLoading = false;
      }
    },
    downloadAll() {
      Object.keys(this.selected).forEach((key) => {
        this.selected[key] = true;
      });
      this.download();
    },
  },
};
</script>

<style>
.form-group {
  margin-bottom: 0 !important;
}
</style>
