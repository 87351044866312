<template>
  <ApexChart v-if="chartData && chartData.series" element="RpmFuelSpeed" :chartOption="chartData"
    :series="chartData.series" />
</template>

<script>
import moment from 'moment';

export default {
  name: 'RpmFuelSpeed',
  props: ['series'],
  computed: {
    chartData() {
      if (!this.series) return null;
      const isMobile = window.innerWidth <= 991;

      const MappedSpeed = this.series.map((data) => (data?.gpsData?.speed * 0.5399568034557236).toFixed(2));
      const MappedPortInFlow = this.series.map((data) => (data.fmData.PORT_InFlow || 0).toFixed(2));
      const MappedStarboardInFlow = this.series.map((data) => (data.fmData.STARBOARD_InFlow || 0).toFixed(2));
      const MappedPortRpm = this.series.map((data) => (data.rpmData.PORT.rpm || 0).toFixed(2));
      const MappedStarboardRpm = this.series.map((data) => (data.rpmData.STARBOARD.rpm || 0).toFixed(2));
      const MappedPortRunningHour = this.series.map((data) => (data.rpmData.PORT.runningTime || 0).toFixed(2));
      const MappedStarboardRunningHour = this.series.map((data) => (data.rpmData.STARBOARD.runningTime || 0).toFixed(2));

      let MaxSpeed = Math.max(...MappedSpeed);
      // eslint-disable-next-line no-mixed-operators
      MaxSpeed += (MaxSpeed / 100 * 10);

      let MaxInFlow = Math.max(...MappedPortInFlow, ...MappedStarboardInFlow);
      // eslint-disable-next-line no-mixed-operators
      MaxInFlow += (MaxInFlow / 100 * 10);

      let MaxRpm = Math.max(...MappedPortRpm, ...MappedStarboardRpm);
      MaxRpm = (Math.ceil(MaxRpm / 100) * 100) + 100;

      return {
        series: [
          {
            name: 'Speed (Knot)',
            data: MappedSpeed,
            color: '#0084ff', // Biru cerah
          },
          {
            name: 'Port Flow (L/h)',
            data: MappedPortInFlow,
            color: '#8B5CF6', // Ungu
          },
          {
            name: 'Starboard Flow (L/h)',
            data: MappedStarboardInFlow,
            color: '#10B981', // Hijau tosca
          },
          {
            name: 'Port RPM',
            data: MappedPortRpm,
            color: '#EF4444', // Merah cerah
          },
          {
            name: 'Starboard RPM',
            data: MappedStarboardRpm,
            color: '#F97316', // Oranye
          },
          {
            name: 'Port Running Time (seconds)',
            data: MappedPortRunningHour,
            color: '#3B82F6', // Biru berbeda
          },
          {
            name: 'Starboard Running Time (seconds)',
            data: MappedStarboardRunningHour,
            color: '#F59E0B', // Kuning-oranye
          },
        ],
        chart: {
          height: 600,
          width: '100%',
          type: 'line',
          stacked: false,
        },
        markers: {
          size: 3,
        },
        stroke: {
          width: 3,
        },
        legend: {
          position: 'bottom',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter(val) {
              return moment(val).format('DD-MM-YYYY HH:mm');
            },
          },
          categories: this.series.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
          tickAmount: isMobile ? 3 : undefined,
        },
        yaxis: [
          {
            max: MaxSpeed,
            title: {
              text: 'Speed (Knot)',
            },
            show: !isMobile,
          },
          {
            max: MaxInFlow,
            title: {
              text: 'Port Flow (L/h)',
            },
            show: !isMobile,
          },
          {
            max: MaxInFlow,
            title: {
              text: 'Stbd. Flow (L/h)',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: MaxRpm,
            title: {
              text: 'Port RPM',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: MaxRpm,
            title: {
              text: 'Stbd. RPM',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: 5000,
            title: {
              text: 'Port Run. Time (seconds)',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: 5000,
            title: {
              text: 'Stbd. Run. Time (seconds)',
            },
            show: !isMobile,
          },
        ],
      };
    },
  },
  methods: {
  },
};
</script>

<style scoped></style>
