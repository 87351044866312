<template>
  <b-container fluid>
    <div class="iq-card mt-3 px-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-3">
          <h4 class="card-title">Sensor Category</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options :access="'master_sensor_category'" :hideAdd="false" :hideDetail="true"
                :isRefreshEnabled="true" :isRefreshed="isRefreshed" @onAddItemClicked="openAddForm"
                @onRefreshed="getData('Refreshed')" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col md="4">
            <label for="" class="m-0">Sensor Category Type:</label>
            <b-form-select v-model="filter.mscaMstyId" :options="sensorTypeData" @change="getData('Refreshed')" class="mb-2"
              size="sm" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div class="table-responsive">
              <b-table striped hover fixed head-variant="dark" show-empty responsive :busy="isLoading" :fields="fields"
                :items="canRead('master_sensor_category') ? data : []"
                style="border-radius: 10px; overflow: scroll; max-height: 500px;">
                <template v-slot:table-busy="">
                  <div class="text-center text-danger my-2">
                    <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
                  </div>
                </template>
                <template v-slot:cell(matyCreatedTime)="data">
                  {{ dateWithDDMMYYYYFormat(data.value) }}
                </template>
                <template v-slot:cell(action)="data">
                  <div class="text-center">
                    <button-options :access="'master_sensor_category'" :hideAdd="true" :hideDelete="false"
                      :hideDetail="true" :hideEdit="false" @onDeleteClicked="openDelete(data.item)"
                      @onEditClicked="openEdit(data.item)" />
                  </div>
                </template>
              </b-table>

              <b-pagination v-model="pagination.current" :per-page="pagination.pageSize"
                :total-rows="pagination.totalSize" @change="onPageChanged"></b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayForm">
        <div class="iq-card mb-0" style="min-width: 300px;">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title" v-if="!current.mscaId">Add New Sensor Category</h4>
              <h4 class="card-title" v-if="current.mscaId">Edit Sensor Category</h4>
            </div>
          </div>
          <div class="iq-card-body">
            <div class="row mb-3">
              <div role="group" class="form-group col-md-6">
                <label for="userId" class="d-block form-label text-left">
                  Sensor Category Name:
                </label>
                <div class="bv-no-focus-ring">
                  <div role="group" class="input-group">
                    <input class="form-control" type="text" id="mscaName" placeholder="Sensor Category Name"
                      v-model="current.mscaName" :class="errors.mscaName ? 'is-invalid' : ''">
                    <b-form-invalid-feedback v-if="errors.mscaName">
                      {{ Object.values(errors.mscaName) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>

              <div role="group" class="form-group col-md-6">
                <label for="userId" class="d-block form-label text-left">
                  Sensor Category Type:
                </label>
                <div class="bv-no-focus-ring">
                  <div role="group" class="input-group">
                    <b-form-select class="form-control" type="text" id="mscaMstyId" placeholder="Sensor Category Type"
                      v-model="current.mscaMstyId" :options="sensorTypeData" :class="errors.mscaMstyId ? 'is-invalid' : ''" />
                    <b-form-invalid-feedback v-if="errors.mscaMstyId">
                      {{ Object.values(errors.mscaMstyId) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <b-button @click="onCancel" class="mr-1" size="sm" :disabled="isSubmit" variant="outline-danger">
                <div class="mx-2">Cancel</div>
              </b-button>
              <b-button type="submit" @click="onSubmitClicked" :disabled="isSubmit" variant="primary" size="sm"
                class="ml-1">
                <div class="mx-2">
                  <i v-if="isSubmit" class="ri-loader-line"></i>
                  Submit
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayDeletion">
        <delete-item :isSubmit="isSubmit" @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import DeleteItem from '@/components/directives/delete-item';
import ButtonOptions from '@/components/directives/button-options';
import SensorCategorySvc from '@/services/SensorCategorySvc';
import SensorTypeSvc from '@/services/SensorTypeSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'MasterSensorCategory',
  components: {
    DeleteItem, ButtonOptions,
  },
  data() {
    return {
      sensorCategory: new SensorCategorySvc(),
      current: {},
      data: [],
      sensorTypeData: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
      fields: [
        {
          key: 'mscaName',
          label: 'Sensor Category Name',
          class: 'text-left',
        },
        {
          key: 'mscaMsty.mstyName',
          label: 'Sensor Category Type',
          class: 'text-left',
        },
        {
          key: 'mscaCreatedUserId',
          label: 'Created By',
          class: 'text-center',
        },
        {
          key: 'mscaCreatedTime',
          label: 'Created',
          class: 'text-center',
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
        },
      ],
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const deleteResponse = await this.sensorCategory.delete(data.mscaId);
        if (deleteResponse.status.error === 0) {
          this.$toast.success(notification);
          this.displayDeletion = false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(deleteResponse.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      try {
        this.isLoading = true;
        this.isRefreshed = true;

        /** getting sensor type data */
        const sensorType = new SensorTypeSvc();
        const sensorTypeResponse = await sensorType.getAll({ max: 1000 });
        this.sensorTypeData = sensorTypeResponse.data.map((item) => ({ value: item.mstyId, text: item.mstyName }));

        const Response = await this.sensorCategory.getAll({
          ...requestBuilder.buildFilters(this.pagination, this.filter),
        });
        this.data = Response.data;

        this.pagination.totalSize = Response.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        console.error('get data error : ', e);
        this.$toast.error('Something wrong when fetching data');
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    onCancel() {
      this.current = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Sensor Category Deleted', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    async onSubmitClicked() {
      try {
        this.errors = {};
        this.isSubmit = true;

        const Data = {
          mscaName: this.current.mscaName,
          mscaMstyId: this.current.mscaMstyId,
        };

        if (!this.current.mscaId) { // jika tidak ada mscaId maka create
          const createResponse = await this.sensorCategory.create(Data);

          if (createResponse.status.error === 0) {
            await this.getData('Success');
            this.current = {};
          } else {
            this.$toast.error('Error when saving data');
          }
        } else { // jika ada mscaId maka update
          const updateResponse = await this.sensorCategory.update(this.current.mscaId, Data);

          if (updateResponse.status.error === 0) {
            await this.getData('Success');
            this.current = {};
          } else {
            this.$toast.error('Error when Update data');
          }
        }
      } catch (e) {
        console.error('onSubmitClicked error : ', e);
        this.$toast.error('Error when submiting data');
      } finally {
        this.displayForm = false;
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.current = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openEdit(data) {
      this.displayForm = true;
      this.current = { ...this._.find(this.data, (v) => v.mscaId === data.mscaId) };
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
