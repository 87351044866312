import Vue from 'vue';
import VueRouter from 'vue-router';

import AuthorizedLayout from '@/layouts/AuthorizedLayout';
import AuthLayout from '@/layouts/AuthLayout';
import BlankLayout from '@/layouts/BlankLayout';
import UnauthorizedLayout from '@/layouts/UnauthorizedLayout';

import Login from '@/views/auth/login/Index';
import ForgotPassword from '@/views/auth/ForgotPassword';
import UpdatePassword from '@/views/auth/UpdatePassword';

import MasterAsset from '@/views/master/asset/Index.vue';
import MasterAssetDetail from '@/views/master/asset/Detail.vue';
import MasterAssetType from '@/views/master/asset_type/Index';
import MasterBunkerLocation from '@/views/master/bunker_location/Index';
import MasterCompany from '@/views/master/company/Index.vue';
import MasterFuelType from '@/views/master/fuel_type/Index';
import MasterCompanyDetail from '@/views/master/company/Detail.vue';
import MasterDevice from '@/views/master/device/Index.vue';
import MasterDeviceDetail from '@/views/master/device/Detail.vue';
import MasterGroup from '@/views/master/group/Index';
import MasterGroupDetail from '@/views/master/group/Detail';
import MasterRole from '@/views/master/role/Index';
import MasterPermission from '@/views/master/permission/Index';
import MasterRoleDetail from '@/views/master/role/Detail';
import MasterUser from '@/views/master/user/Index';
import MasterUserDetail from '@/views/master/user/Detail';
import MasterConfigRpm from '@/views/master/config/rpm/Index';
import MasterConfigAe from '@/views/master/config/ae/Index';
import MasterConfigTank from '@/views/master/config/tank/Index';
import MasterSensorType from '@/views/master/sensor_type/Index';
import MasterSensorCategory from '@/views/master/sensor_category/Index';
import MasterTank from '@/views/master/tank/Index';
import MasterTankDetail from '@/views/master/tank/Detail';

import Export from '@/views/export/Index.vue';

import Report from '@/views/report/Index';
import Home from '@/views/home/Index.vue';
import Weather from '@/views/home/Weather.vue';
// import EmsDashboard from '@/views/dashboard/EmsDashboard.vue';
import FmsDashboard from '@/views/dashboard/FmsDashboard.vue';
// import EmsReport from '@/views/report/EmsReport.vue';
import FmsReport from '@/views/report/FmsReport.vue';
import FmsDatalog from '@/views/report/FmsReportDatalog.vue';

import BunkerDashboard from '@/views/dashboard/BunkerDashboard.vue';
import BunkeringLog from '@/views/bunkering_log/Index';

import CompanyActiveSelection from '@/views/auth/CompanyActiveSelection';
import AppActiveSelection from '@/views/auth/AppActiveSelection';

import Widgets from '@/views/Widgets';
import Forbidden from '@/views/Forbidden';

import AssetType from '@/views/AssetType';

import store from '@/store';

import middleware from './middleware';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Mantraz',
    component: AuthorizedLayout,
    redirect: '/home',
    beforeEnter: middleware.isAuthenticated(store),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'weather',
        name: 'Weather',
        component: Weather,
      },
      {
        path: 'dashboard/fms',
        name: 'FmsDashboard',
        component: FmsDashboard,
      },
      {
        path: 'dashboard/bunker',
        name: 'BunkerDashboard',
        component: BunkerDashboard,
      },
      {
        path: 'report/fms',
        name: 'FmsReport',
        component: FmsReport,
      },
      {
        path: 'report/datalog/fms',
        name: 'FmsDatalog',
        component: FmsDatalog,
      },
      {
        path: 'bunkering-log',
        name: 'BunkeringLog',
        component: BunkeringLog,
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets,
      },
      {
        path: 'asset/type/',
        name: 'AssetType',
        component: AssetType,
      },
      {
        path: '401',
        name: 'Forbidden',
        component: Forbidden,
      },
      {
        path: 'report',
        name: 'Report',
        component: Report,
      },
    ],
  },
  {
    path: '/preference',
    name: 'Preference',
    component: AuthLayout,
    beforeEnter: middleware.isAuthenticated(store),
    children: [
      {
        path: 'active-company',
        name: 'CompanyActiveSelection',
        component: CompanyActiveSelection,
      },
      {
        path: 'active-app',
        name: 'AppActiveSelection',
        component: AppActiveSelection,
      },
    ],
  },
  {
    path: '/master',
    name: 'Master',
    component: AuthorizedLayout,
    beforeEnter: middleware.isAuthenticated(store),
    children: [
      {
        path: 'config',
        name: 'Config',
        component: BlankLayout,
        children: [
          {
            path: 'rpm',
            name: 'MasterConfigRpm',
            component: MasterConfigRpm,
          },
          {
            path: 'ae',
            name: 'MasterConfigAe',
            component: MasterConfigAe,
          },
          {
            path: 'tank',
            name: 'MasterConfigTank',
            component: MasterConfigTank,
          },
        ],
      },
      {
        path: 'company',
        name: 'Company',
        component: MasterCompany,
      },
      {
        path: 'group',
        name: 'Group',
        component: MasterGroup,
      },
      {
        path: 'asset',
        name: 'Asset',
        component: MasterAsset,
      },
      {
        path: 'device',
        name: 'Device',
        component: MasterDevice,
      },
      {
        path: 'device/:id',
        name: 'AssetDevice',
        component: MasterDevice,
      },
      {
        path: 'device/detail/:id',
        name: 'DeviceDetail',
        component: MasterDeviceDetail,
      },
      {
        path: 'group/detail/:id',
        name: 'GroupDetail',
        component: MasterGroupDetail,
      },
      {
        path: 'company/detail/:id',
        name: 'CompanyDetail',
        component: MasterCompanyDetail,
      },
      {
        path: 'asset/detail/:id',
        name: 'AssetDetail',
        component: MasterAssetDetail,
      },
      {
        path: 'user',
        name: 'User',
        component: MasterUser,
      },
      {
        path: 'user/:id',
        name: 'UserDetail',
        component: MasterUserDetail,
      },
      {
        path: 'role',
        name: 'Role',
        component: MasterRole,
      },
      {
        path: 'role/:id',
        name: 'RoleDetail',
        component: MasterRoleDetail,
      },
      {
        path: 'asset-type',
        name: 'MasterAssetType',
        component: MasterAssetType,
      },
      {
        path: 'fuel-type',
        name: 'MasterFuelType',
        component: MasterFuelType,
      },
      {
        path: 'bunker-location',
        name: 'MasterBunkerLocation',
        component: MasterBunkerLocation,
      },
      {
        path: 'permission',
        name: 'Permission',
        component: MasterPermission,
      },
      {
        path: 'sensor_type',
        name: 'MasterSensorType',
        component: MasterSensorType,
      },
      {
        path: 'sensor_category',
        name: 'MasterSensorCategory',
        component: MasterSensorCategory,
      },
      {
        path: 'tank',
        name: 'MasterTank',
        component: MasterTank,
      },
      {
        path: 'tank/:id',
        name: 'MasterTankDetail',
        component: MasterTankDetail,
      },
    ],
  },
  {
    path: '/auth',
    name: 'UnAuthorized',
    component: UnauthorizedLayout,
    // redirect: '/auth/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
      {
        path: 'update-password/:emailtoken/:token',
        name: 'UpdatePassword',
        component: UpdatePassword,
      },
      {
        path: 'export',
        name: 'Export',
        component: Export,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
