<template>
  <ApexChart v-if="chartData && chartData.series" element="PortFuelCharacteristics" :chartOption="chartData"
    :series="chartData.series" />
</template>

<script>
import moment from 'moment';

export default {
  name: 'PortFuelCharacteristics',
  props: ['series'],
  computed: {
    chartData() {
      if (!this.series) return null;
      const isMobile = window.innerWidth <= 991;

      const MappedPortInFlow = this.series.map((data) => (data.fmData.PORT_InFlow || 0).toFixed(2));
      const MappedPortRpm = this.series.map((data) => (data.rpmData.PORT.rpm || 0).toFixed(2));
      const mappedPortInDens = this.series.map((data) => (data.fmData.PORT_InDens || 0).toFixed(2));
      const mappedPortInTemp = this.series.map((data) => (data.fmData.PORT_IN.temperature || 0).toFixed(2));

      let MaxInFlow = Math.max(...MappedPortInFlow);
      // eslint-disable-next-line no-mixed-operators
      MaxInFlow += (MaxInFlow / 100 * 10);

      let MaxRpm = Math.max(...MappedPortRpm);
      MaxRpm = (Math.ceil(MaxRpm / 100) * 100) + 100;

      let MaxPortInDens = Math.max(...mappedPortInDens);
      // eslint-disable-next-line no-mixed-operators
      MaxPortInDens += (MaxPortInDens / 100 * 10);

      let MaxPortInTemp = Math.max(...mappedPortInTemp);
      // eslint-disable-next-line no-mixed-operators
      MaxPortInTemp += (MaxPortInTemp / 100 * 10);

      return {
        series: [
          {
            name: 'Port In Flow (L/h)',
            data: MappedPortInFlow,
            color: '#0084ff', // Biru cerah
          },
          {
            name: 'Port RPM',
            data: MappedPortRpm,
            color: '#884DFF', // Ungu
          },
          {
            name: 'Port In Density (g/cm³)',
            data: mappedPortInDens,
            color: '#10B981', // Hijau tosca
          },
          {
            name: 'Port In Temp (°C)',
            data: mappedPortInTemp,
            color: '#F97316', // Oranye
          },
        ],
        chart: {
          height: 600,
          width: '100%',
          type: 'line',
          stacked: false,
        },
        markers: {
          size: 3,
        },
        stroke: {
          width: 3,
        },
        legend: {
          position: 'bottom',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter(val) {
              return moment(val).format('DD-MM-YYYY HH:mm');
            },
          },
          categories: this.series.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
          tickAmount: isMobile ? 3 : undefined,
        },
        yaxis: [
          {
            max: MaxInFlow,
            title: {
              text: 'Port Flow (L/h)',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: MaxRpm,
            title: {
              text: 'Port RPM',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: MaxPortInDens,
            title: {
              text: 'Port Density (g/cm³)',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: MaxPortInTemp,
            title: {
              text: 'Port Temperature (°C)',
            },
            show: !isMobile,
          },
        ],
      };
    },
  },
  methods: {
  },
};
</script>

<style scoped></style>
