<template>
  <div>
    <div class="wrapper">
      <b-navbar toggleable="lg" type="light">
        <b-navbar-brand :to="{ name: 'Home' }">
          <img :src="logo" alt="Logo" class="d-inline-block align-top">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-if="activeApp?.name === 'fms'">
            <!-- Home Link -->
            <b-nav-item :to="{ name: 'Home' }" :class="{ 'active': $route.name === 'Home' }"
              class="d-flex align-items-center">
              <img :src="$route.name === 'Home' ? icon.IconHomeActive : icon.IconHome" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Home' }">Home</span>
            </b-nav-item>
            <!--/ Home Link -->

            <!-- Dashboard Link -->
            <b-nav-item :to="{ name: 'FmsDashboard' }" :class="{ 'active': $route.name === 'FmsDashboard' }"
              class="d-flex align-items-center">
              <img :src="icon.IconDashboard" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'FmsDashboard' }">Dashboard</span>
            </b-nav-item>
            <!--/ Dashboard Link -->

            <!-- Report Dropdown -->
            <b-nav-item-dropdown :class="{ 'active': $route.name === 'FmsReport' || $route.name === 'FmsDatalog' }"
              text="Report" class="d-flex align-items-center" right>
              <template #button-content>
                <img :src="icon.IconReport" class="mr-2" />
                <span :class="{ 'pink-text': $route.name === 'FmsReport' }">Report</span>
              </template>
              <b-dropdown-item :to="{ name: 'FmsReport' }">
                Report Summary
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'FmsDatalog' }">
                Report Datalog
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <!--/ Report Dropdown -->

            <!-- Dashboard Link -->
            <b-nav-item :href="'/weather'" :class="{ 'active': $route.name === 'Weather' }"
              class="d-flex align-items-center"
              v-if="userAccess.weather && userAccess.weather.find((x) => x === 'read')">
              <img :src="icon.IconWeather" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Weather' }">Weather</span>
            </b-nav-item>
            <!--/ Dashboard Link -->

            <!-- Asset Link -->
            <b-nav-item :to="{ name: 'Asset' }" :class="{ 'active': $route.name === 'Asset' }"
              class="d-flex align-items-center">
              <img :src="icon.IconAsset" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Asset' }">Asset</span>
            </b-nav-item>
            <!--/ Asset Link  -->

            <!-- Asset Link -->
            <b-nav-item :to="{ name: 'Device' }" :class="{ 'active': $route.name === 'Device' }"
              class="d-flex align-items-center">
              <img :src="icon.IconDevice" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Device' }">Device</span>
            </b-nav-item>
            <!--/ Asset Link  -->
          </b-navbar-nav>

          <b-navbar-nav v-if="activeApp?.name === 'bunkering'">
            <!-- Home Link -->
            <b-nav-item :to="{ name: 'BunkerDashboard' }" :class="{ 'active': $route.name === 'BunkerDashboard' }"
              class="d-flex align-items-center">
              <img :src="$route.name === 'Home' ? icon.IconHomeActive : icon.IconHome" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'BunkerDashboard' }">Home</span>
            </b-nav-item>
            <!--/ Home Link -->

            <!-- Bunker Log Link -->
            <b-nav-item :to="{ name: 'BunkeringLog' }" :class="{ 'active': $route.name === 'BunkeringLog' }"
              class="d-flex align-items-center">
              <img :src="icon.IconBunkeringLog" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'BunkeringLog' }">Bunker Log</span>
            </b-nav-item>
            <!--/ Bunker Log Link -->

            <!-- Asset Link -->
            <b-nav-item :to="{ name: 'Asset' }" :class="{ 'active': $route.name === 'Asset' }"
              class="d-flex align-items-center">
              <img :src="icon.IconAsset" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Asset' }">Asset</span>
            </b-nav-item>
            <!--/ Asset Link  -->

            <!-- Asset Link -->
            <b-nav-item :to="{ name: 'Device' }" :class="{ 'active': $route.name === 'Device' }"
              class="d-flex align-items-center">
              <img :src="icon.IconDevice" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Device' }">Device</span>
            </b-nav-item>
            <!--/ Asset Link  -->
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">

            <!-- Select Company and App Dropdown -->
            <b-nav-item-dropdown v-if="!hideCompanySelection" text="Options" style="display: none" class="d-sm-block"
              right>
              <template #button-content>
                <img :src="icon.IconSelection" class="mr-2" style="width: 18px; height: 18px;">
                <span>Select App/Company</span>
              </template>

              <!-- Dropdown Item (hidden on mobile) -->
              <b-dropdown-item v-on:click="openSelectAppModal">
                <img :src="icon.IconApp" alt="App Icon" class="mr-2" style="width: 22px; height: 22px;">
                Select Application
              </b-dropdown-item>
              <b-dropdown-item v-on:click="openSelectCompanyModal">
                <img :src="icon.IconCompany" alt="Company Icon" class="mr-2">
                Select Company
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <!--/ Select Company and App Dropdown -->

            <!-- Select App (hidden on tablet and desktop) -->
            <b-nav-item text="Select App" :class="{ 'd-block d-sm-none': !hideCompanySelection }"
              class="d-flex align-items-center" v-on:click="openSelectAppModal" right>
              <img :src="icon.IconApp" alt="App Icon" class="mr-2" style="width: 22px; height: 22px;">
              <span>Select App</span>
            </b-nav-item>
            <!--/ Select App -->

            <!-- Select Company (hidden on tablet and desktop) -->
            <b-nav-item v-if="!hideCompanySelection" text="Select Company"
              class="d-block d-sm-none d-flex align-items-center" v-on:click="openSelectCompanyModal" right>
              <img :src="icon.IconCompany" class="mr-2">
              <span>Select Company</span>
            </b-nav-item>
            <!--/ Select Company -->

            <!-- dropdown user -->
            <b-nav-item-dropdown class="d-flex align-items-center" right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <img :src="profileImage" class="mr-2" :style="{ width: '25px', height: '25px' }">
                <span class="truncate">{{ userId }}</span>
              </template>
              <b-dropdown-item v-if="userAccess.master_user && userAccess.master_user.find((x) => x === 'read')"
                :to="{ name: 'User' }">
                Users List
              </b-dropdown-item>
              <b-dropdown-item v-if="userAccess.master_role && userAccess.master_role.find((x) => x === 'read')"
                :to="{ name: 'Role' }">
                Role
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userAccess.master_permission && userAccess.master_permission.find((x) => x === 'read')"
                :to="{ name: 'Permission' }">
                Permission
              </b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item
                v-if="userAccess.master_group_company && userAccess.master_group_company.find((x) => x === 'read')"
                :to="{ name: 'Group' }">
                Group
              </b-dropdown-item>
              <b-dropdown-item v-if="userAccess.master_company && userAccess.master_company.find((x) => x === 'read')"
                :to="{ name: 'Company' }">
                Company
              </b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item :to="{ name: 'MasterAssetType' }"
                v-if="userAccess.master_fuel_type && userAccess.master_fuel_type.find((x) => x === 'read')">
                Asset Type
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'MasterSensorType' }"
                v-if="userAccess.master_sensor_type && userAccess.master_sensor_type.find((x) => x === 'read')">
                Sensor Type
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'MasterSensorCategory' }"
                v-if="userAccess.master_sensor_category && userAccess.master_sensor_category.find((x) => x === 'read')">
                Sensor Category
              </b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item :to="{ name: 'MasterTank' }"
                v-if="userAccess.master_tank_header && userAccess.master_tank_header.find((x) => x === 'read')">
                Tank Header/Detail
              </b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item
                v-if="userAccess.master_fuel_type && userAccess.master_fuel_type.find((x) => x === 'read')"
                :to="{ name: 'MasterFuelType' }">
                Fuel Type
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userAccess.master_bunker_location && userAccess.master_bunker_location.find((x) => x === 'read')"
                :to="{ name: 'MasterBunkerLocation' }">
                Bunker Location
              </b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item
                v-if="userAccess.master_config_rpm && userAccess.master_config_rpm.find((x) => x === 'read')"
                :to="{ name: 'MasterConfigRpm' }">
                Config RPM
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userAccess.master_config_ae && userAccess.master_config_ae.find((x) => x === 'read')"
                :to="{ name: 'MasterConfigAe' }">
                Config AE
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userAccess.master_config_tank && userAccess.master_config_tank.find((x) => x === 'read')"
                :to="{ name: 'MasterConfigTank' }">
                Config Tank
              </b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item @click="handleLogout">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
            <!--/ dropdown user -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <b-modal id="select-app-modal" title="Select App" centered hide-footer>
        <app-active-selection maxWidth=true hideHeader=true />
      </b-modal>

      <b-modal id="select-company-modal" title="Select Company" centered hide-footer>
        <company-active-selection maxWidth=true hideHeader=true disableRedirection=true />
      </b-modal>

      <div>
        <transition name="router-anim">
          <router-view :key="$store.state.remountKey" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import logo from '@/assets/images/Mantraz-logo.svg';
import profileImage from '@/assets/images/user/default-user.png';
import { sofbox } from '@/config/pluginInit';

import AppActiveSelection from '@/views/auth/AppActiveSelection';
import CompanyActiveSelection from '@/views/auth/CompanyActiveSelection';

import IconHome from '@/assets/images/navbar/home.svg';
import IconWeather from '@/assets/images/navbar/weather.svg';
import IconHomeActive from '@/assets/images/navbar/home-active.svg';
import IconDashboard from '@/assets/images/navbar/dashboard.svg';
import IconDashboardActive from '@/assets/images/navbar/dashboard-active.svg';
import IconReport from '@/assets/images/navbar/report.svg';
import IconReportActive from '@/assets/images/navbar/report-active.svg';
import IconAsset from '@/assets/images/navbar/asset.svg';
import IconAssetActive from '@/assets/images/navbar/asset-active.svg';
import IconCompany from '@/assets/images/navbar/company.svg';
import IconSelection from '@/assets/images/navbar/selection.svg';
import IconApp from '@/assets/images/navbar/application.svg';
import IconDevice from '@/assets/images/navbar/device.svg';
import IconBunkeringLog from '@/assets/images/navbar/bunkering-log.svg';
import IconBunkeringLogActive from '@/assets/images/navbar/bunkering-log-active.svg';

export default {
  name: 'AuthorizedLayoutTop',
  components: {
    AppActiveSelection,
    CompanyActiveSelection,
  },
  computed: {
    ...mapState(['remountKey']),
    activeCompany() {
      return this.$store.getters['auth/activeCompany'];
    },
    userAccess() {
      const access = this.$store.getters['auth/userAccess'];
      return access;
    },
    userId() {
      return this.$store.getters['auth/userId'];
    },
    activeApp() {
      return this.$store.getters['auth/activeApp'];
    },
  },
  data() {
    return {
      hideCompanySelection: false,
      logo,
      profileImage,
      isSmallSidebar: false,
      availableCompany: [],
      icon: {
        IconHome,
        IconWeather,
        IconHomeActive,
        IconDashboard,
        IconDashboardActive,
        IconReport,
        IconReportActive,
        IconAsset,
        IconAssetActive,
        IconCompany,
        IconSelection,
        IconApp,
        IconDevice,
        IconBunkeringLog,
        IconBunkeringLogActive,
      },
    };
  },
  mounted() {
    sofbox.mainIndex();
    this.availableCompany = this.$store.getters['auth/availableCompany'];

    const availableCompaniesCount = Object.values(this.$store.getters['auth/availableCompany']).length;
    if (availableCompaniesCount === 1) this.hideCompanySelection = true;
  },
  methods: {
    onMaxMinSidebar() {
      this.isSmallSidebar = !this.isSmallSidebar;
    },
    handleLogout() {
      this.$store.dispatch('auth/logout');
      window.location.href = '/auth/login';
    },
    openSelectAppModal() {
      this.$bvModal.show('select-app-modal');
    },
    openSelectCompanyModal() {
      this.$bvModal.show('select-company-modal');
    },
  },
  watch: {
    shouldRemount(newValue) {
      if (newValue) {
        console.debug('duar');
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style>
@media (max-width: 991px) {

  .leaflet-top,
  .leaflet-bottom {
    z-index: 400 !important;
  }

  .navbar-collapse {
    top: 70px !important;
    position: fixed !important;
    z-index: 1999 !important;
  }

  .navbar-nav .dropdown-menu {
    position: relative !important;
    max-height: 200px !important;
    overflow: scroll !important;
  }
}

.truncate {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-brand img {
  width: 72px;
}

.navbar {
  background-color: white;
  padding: 0 1rem !important;
  height: 70px;
  z-index: 999;
}

.navbar-nav {
  height: 100%;
}

.user-profile-image {
  width: 25px;
}

.nav-item {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-link {
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.nav-link img {
  height: 14px;
}

.nav-link span {
  color: black;
  font-weight: 600;
  letter-spacing: 1.4px;
}

.active {
  color: #ff69b4;
  position: relative;
}

.active::after {
  content: "";
  position: absolute;
  width: 100%;
  border-radius: 2px;
  height: 6px;
  bottom: 0px;
  left: 0;
  background-color: #ff69b4;
}

.pink-text {
  color: #ff69b4 !important;
}
</style>
